import {FireSpecificQuestions} from "@/components/models/FireSpecificQuestions";
import {InsectOrRodentSpecificQuestions} from "@/components/models/InsectOrRodentSpecificQuestions";
import {MoldOrMildewSpecificQuestions} from "@/components/models/MoldOrMildewSpecificQuestions";
import {WaterSpecificQuestions} from "@/components/models/WaterSpecificQuestions";
import {OtherSpecificQuestions} from "@/components/models/OtherSpecificQuestions";
import {TheftSpecificQuestions} from "@/components/models/TheftSpecificQuestions";

export class LossDetails {
  dateOfDiscovery!: string;
  timeOfDiscovery!: string;
  dateOfLoss!: string;
  timeOfLoss!: string;
  lossCause!: string;
  lossCauseCode!: string;
  detailedLossCause!: string;
  detailedLossCauseCode!: string;
  lossDescription!: string;
  doYouKnowDateLossOccurred!: Boolean;
  fireSpecificQuestions: FireSpecificQuestions = new FireSpecificQuestions();
  insectOrRodentSpecificQuestions: InsectOrRodentSpecificQuestions = new InsectOrRodentSpecificQuestions();
  moldOrMildewSpecificQuestions: MoldOrMildewSpecificQuestions = new MoldOrMildewSpecificQuestions();
  otherSpecificQuestions: OtherSpecificQuestions = new OtherSpecificQuestions();
  theftSpecificQuestions: TheftSpecificQuestions = new TheftSpecificQuestions();
  waterSpecificQuestions: WaterSpecificQuestions = new WaterSpecificQuestions();
}


  import {Component, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {DateUtils} from "@/components/utils/date-utils";
  import {Status} from "@/components/models/Status";
  import CancelClaimModal from "@/views/CancelClaimModal.vue";
  import FireReviewAndSubmit from "@/views/review-and-submit-components/FireReviewAndSubmit.vue";
  import InsectOrRodentReviewAndSubmit from "@/views/review-and-submit-components/InsectOrRodentReviewAndSubmit.vue";
  import MoldOrMildewReviewAndSubmit from "@/views/review-and-submit-components/MoldOrMildewReviewAndSubmit.vue";
  import TheftReviewAndSubmit from "@/views/review-and-submit-components/TheftReviewAndSubmit.vue";
  import WaterReviewAndSubmit from "@/views/review-and-submit-components/WaterReviewAndSubmit.vue";
  import OtherReviewAndSubmit from "@/views/review-and-submit-components/OtherReviewAndSubmit.vue";
  import {UiUtils} from "@/components/utils/ui-utils";
  import {PageUtils} from "@/components/utils/page-utils/page-utils";

  @Component({
    components: {
      OtherReviewAndSubmit,
      WaterReviewAndSubmit,
      TheftReviewAndSubmit,
      MoldOrMildewReviewAndSubmit, InsectOrRodentReviewAndSubmit, FireReviewAndSubmit, CancelClaimModal}
  })
  export default class ReviewAndSubmitPage extends Vue {

    submission = new FnolSubmission();
    dateUtil = new DateUtils();
    uiUtils = new UiUtils();
    pageUtils = new PageUtils();
    windowWidth: number = 0;

    created() {
      this.submission = this.$store.state.submission;
      this.windowWidth = window.innerWidth;

      // Check for "back button" use from Confirmation page.
      this.$store.dispatch("backToLanding", this.submission);
    }

    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    }

    navigateTo(pageName: string) {
      this.$store.dispatch('saveSubmission', this.submission).then(() => {
        window.scrollTo(0, 0);
        this.$router.push({
          name: pageName
        });
      });
    }

    submit(pageName: string) {
      this.submission.status = Status.IN_REVIEW;
      this.pageUtils.updateClaimSubmittedByInfoOnSubmit(this.submission);
      this.$store.dispatch('saveSubmission', this.submission).then(() => {
        window.scrollTo(0, 0);
        this.$router.push({
          name: pageName
        });
      });
    }
  }


  import {Component, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {FnolService} from "@/services/fnol-service";
  import {DateUtils} from "@/components/utils/date-utils";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {UiUtils} from "@/components/utils/ui-utils";
  import {Status} from "@/components/models/Status";
  import CancelClaimModal from "@/views/CancelClaimModal.vue";
  import {PageUtils} from "@/components/utils/page-utils/page-utils";
  import {CustomerServiceRep} from "@/components/models/CustomerServiceRep";
  import {CommonContact} from "@/components/models/CommonContact";
  import {IsoValidation} from "@/components/utils/iso-validation";

  @Component({
    components: {CancelClaimModal}
  })
  export default class AdditionalInformation extends Vue {

    windowWidth: number = 0;
    submission: FnolSubmission = new FnolSubmission();
    fnolService: FnolService = new FnolService();
    dateUtils: DateUtils = new DateUtils();
    validationUtils: ValidationUtils = new ValidationUtils();
    uiUtils: UiUtils = new UiUtils();
    pageUtils: PageUtils = new PageUtils();

    dateOfNotificationPicker: Boolean = false;
    formattedDateOfNotification: string;
    dateOfNotification: string;

    errorSnackbar: Boolean = false;

    dateOfNotificationValidationRules: Array<string> = [];
    radioButtonValidationRules: Array<string> = [];
    managerPhoneValidationRules: Array<string> = [];
    managerFirstNameValidationRules: Array<string> = [];
    managerLastNameValidationRules: Array<string> = [];
    additionalInfoValidationFlag = false;
    customerServiceRepArray: Array<CustomerServiceRep> = [];
    isoValidationUtils: IsoValidation = new IsoValidation();

    created() {
      console.log("additional information created");
      this.submission = this.$store.state.submission;
      this.windowWidth = window.innerWidth;

      if(this.submission.additionalInformation != null && this.submission.additionalInformation.dateOfNotification != null) {
        this.formatDatesForDisplayOnLoad();
      }
      // Check for "back button" use from Confirmation page.
      this.$store.dispatch("backToLanding", this.submission);

      if(this.$store.state.isAccessInternal){
        this.$store.dispatch('getInternalAdminData').then(() => {
           this.customerServiceRepArray = this.$store.state.customerServiceRepList
        });
      }

      if(!this.submission.commonData.claimPreparer && this.$store.state.isAccessInternal) {
        this.submission.commonData.claimPreparer = new CommonContact();
      }

    }

    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    }

    navigateTo(pageName: string) {
      this.saveCommonData();
      this.$store.dispatch('saveSubmission', this.submission).then(() => {
        window.scrollTo(0, 0);
        this.$router.push({
          name: pageName
        });
      });
    }

    async triggerValidations(){
      let inputEvent = new Event('input');

      this.additionalInfoValidationFlag = true;

      if (this.submission.additionalInformation.managerNotified === true) {
        this.validationUtils.triggerEvent('date-notification-input', inputEvent);
      }
    }

    saveCommonData() {
      if(this.$store.state.isAccessInternal) {
        this.submission.commonData.claimPreparer.firstName = this.submission.additionalInformation.customerServiceRep.firstName;
        this.submission.commonData.claimPreparer.lastName = this.submission.additionalInformation.customerServiceRep.lastName;
        this.submission.commonData.claimPreparer.emailAddress = this.submission.additionalInformation.customerServiceRep.email;
      }
    }

    async submitLossCTA() {
      await this.triggerValidations();
      if (!this.$store.state.addInfoPageIsValid) {
        window.scrollTo(0, 0);
        this.errorSnackbar = true;
        return;
      } else {
        this.saveCommonData();
        this.errorSnackbar = false;
        this.submission.status = Status.IN_REVIEW;
        this.pageUtils.updateClaimSubmittedByInfoOnSubmit(this.submission);
        this.$store.dispatch('saveSubmission', this.submission).then(() => {
          console.log("getting fnol from store");
        });
        window.scrollTo(0, 0);
        this.$router.push({
          name: 'confirmation'
        });
      }
    }

    async reviewSubmissionCTA() {
      await this.triggerValidations();
      if (!this.$store.state.addInfoPageIsValid) {
        window.scrollTo(0, 0);
        this.errorSnackbar = true;
        return;
      } else {
        this.saveCommonData();
        this.errorSnackbar = false;
        window.scrollTo(0, 0);
        this.$router.push({
          name: 'review-and-submit'
        })
      }
    }

    enteredDateOfNotificationOnInput() {
      this.submission.additionalInformation.dateOfNotification = this.dateUtils.parseFormattedDate(this.formattedDateOfNotification);
      this.dateOfNotificationValidationRules = this.runDateOfeNotificationValidationRules();
      this.dateOfNotificationPicker = false;

    }

    dateNotificationOnInput() {
      this.formattedDateOfNotification = this.dateUtils.formatDateForDisplay(this.submission.additionalInformation.dateOfNotification);
      this.dateOfNotificationValidationRules = this.runDateOfeNotificationValidationRules();
      this.dateOfNotificationPicker = false;

    }

    formatDatesForDisplayOnLoad() {
      console.log("formatting dates for display")
      this.formattedDateOfNotification = this.dateUtils.formatDateForDisplay(this.submission.additionalInformation.dateOfNotification);
    }

    runDateOfeNotificationValidationRules(): Array<any> {
      if(this.submission.additionalInformation.dateOfNotification === null){
        this.formattedDateOfNotification = null;
      }
      if (this.submission.additionalInformation.managerNotified) {
        let rules = [
          (v: any) => !!v || 'Date of Notification is required',
          (v: any) => this.validationUtils.validateDateNotPastOrFuture(v) || 'Please enter a present or past date',
          (v: any) => this.validationUtils.validateDateNotBeforeAnotherDate(v, '01/01/1900') || 'Year must be greater than 1900',
          (v: any) => this.validationUtils.validateDateNotBeforeAnotherDate(v, this.dateOfNotification)
            || 'Date of Notification cannot be before the Date of Injury ('
            + this.dateUtils.formatDateForDisplay(this.dateOfNotification) + ')',
          (v: any) => this.validationUtils.validateValidDate(v) || 'Please enter a valid date',
          (v: any) => this.validationUtils.validateDateIsValidLength(v) || 'Please enter a valid date'
        ];
        return rules;
      }
    }

    managerPhoneOnKeyUp() {
      this.managerPhoneValidationRules = this.runManagerPhoneValidationRules();
    }

    managerFirstNameOnInput() {
      this.managerFirstNameValidationRules = this.runManagerFirstNameValidationRules();
    }

    managerLastNameOnInput() {
      this.managerLastNameValidationRules = this.runManagerLastNameValidationRules();
    }

    resetManagerValues(){
      this.submission.additionalInformation.unitInspectedByManager = null;
      this.submission.additionalInformation.unitPhotoByManager = null;
      this.submission.additionalInformation.dateOfNotification = null;
      this.submission.additionalInformation.managerFirstName = null;
      this.submission.additionalInformation.managerLastName = null;
      this.submission.additionalInformation.managerPhoneNumber = null;
      this.dateOfNotificationValidationRules = [];
      this.managerFirstNameValidationRules = [];
      this.managerLastNameValidationRules = [];
      this.managerPhoneValidationRules = [];
    }

    runManagerPhoneValidationRules(): Array<any> {
      let rules = [
        (v: any) => this.validationUtils.validatePhoneNumberIsValidLength(v) || 'Please enter a valid phone number',
        (v: any) => this.validationUtils.validatePhoneNumberIsNotAllSameNumbers(v) || 'Phone number may not be all same digits'];
      return rules;
    }

    runManagerFirstNameValidationRules(): Array<any> {
      let rules = [
        (v: any) => this.validationUtils.validateMaxLength(v, 30) || 'Max 30 characters',
        (v: any) => this.isoValidationUtils.isValid(v, false) || 'The name entered is invalid',
        (v: any) => this.validationUtils.validateAlphaWithSpaceChars(v) || 'Cannot contain numbers or special characters']
      return rules;
    }

    runManagerLastNameValidationRules(): Array<any> {
      let rules = [
        (v: any) => this.validationUtils.validateMaxLength(v, 30) || 'Max 30 characters',
        (v: any) => this.isoValidationUtils.isValid(v, false) || 'The name entered is invalid',
        (v: any) => this.validationUtils.validateAlphaWithSpaceChars(v) || 'Cannot contain numbers or special characters'];
      return rules;
    }

    rentPaymentsErrorMessage(){
      if(this.additionalInfoValidationFlag) {
        if (this.submission.additionalInformation.rentPayments === null) {
          return "Were you up to date on your rent payments at the time of loss? is required"
        } else {
          return null;
        }
      }
    }

    managerNotifiedErrorMessage(){
      if(this.additionalInfoValidationFlag) {
        if (this.submission.additionalInformation.managerNotified === null) {
          return "Did you notify the manager? is required"
        } else {
          return null;
        }
      }
    }

    unitInspectedErrorMessage(){
      if(this.submission.additionalInformation.managerNotified) {
        if (this.additionalInfoValidationFlag) {
          if (this.submission.additionalInformation.unitInspectedByManager === null) {
            return "Did manager inspect the unit? is required"
          } else {
            return null;
          }
        }
      }
      return null;
    }

    unitPhotoErrorMessage(){
      if(this.submission.additionalInformation.managerNotified) {
        if (this.additionalInfoValidationFlag) {
          if (this.submission.additionalInformation.unitPhotoByManager === null) {
            return "Did manager photograph the unit? is required"
          } else {
            return null;
          }
        }
      }
      return null;
    }

    unitDateOfNotificationErrorMessage(){
      if(this.submission.additionalInformation.managerNotified) {
        if (this.additionalInfoValidationFlag) {
          if (this.submission.additionalInformation.dateOfNotification === null) {
            return "Date of Notification is required"
          } else {
            return null;
          }
        }
      }
      return null;
    }

    consentToTextErrorMessage() {
      if(this.additionalInfoValidationFlag) {
        if (this.submission.additionalInformation.consentToTextTenant === null) {
          return "Can we contact the tenant via text messages? is required"
        } else {
          return null;
        }
      }
    }

  }

import {Vue} from "vue-property-decorator";
import {ValidationUtils} from "../validation-utils"

export class InsectRodentSpecificValidationUtils extends Vue {

  validationUtils: ValidationUtils = new ValidationUtils();

  runLossDescriptionValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Loss Description is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runtypeOfInsectValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Type of insect / rodent / vermin? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runHowItemsDamagedValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'How are the items damaged? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
    (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runWhatItemsDamagedValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'What items were damaged? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
    (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runDetailLossCauseValidationRules(): Array<any>{
    const rules = [
      (v: any) => !!v || 'Detail Loss Cause is required'];
    return rules;
  }

}

import {FnolApi} from "@/services/backend-api";
import {Vue} from 'vue-property-decorator'
import {FnolSubmission} from "@/components/models/FnolSubmission";
import {InternalAdminData} from "@/components/models/InternalAdminData";

export class FnolService extends Vue {

  api = new FnolApi();

  async getKey(): Promise<Record<string, any>> {
    return await this.api.getKey();
  }

  async isTokenValid(token: string): Promise<boolean> {
    return await this.api.isTokenValid(token);
  }

  async isInternal(): Promise<boolean> {
    return await this.api.isInternal();
  }

  async getFnol(id: string): Promise<FnolSubmission> {
    if (null == id) {
      return new FnolSubmission();
    }
    return await this.api.getSubmission(id);
  }

  async saveFnol(fnol: FnolSubmission): Promise<FnolSubmission> {

    let data: FnolSubmission = new FnolSubmission();
    if (null == fnol.submissionId) {
      await this.api.createSubmission(fnol).then(value => {
        data = value;
      })
    } else {
      await this.api.updateSubmission(fnol).then(value => {
        data = value;
      })
    }
    return data;
  }

  async getInternalAdminData(): Promise<InternalAdminData> {
    return await this.api.getInternalAdminData();
  }

}


  import {Component, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import CancelClaimModal from "@/views/CancelClaimModal.vue";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {PageUtils} from "@/components/utils/page-utils/page-utils";

  @Component({
    components: {CancelClaimModal}
  })

  export default class RentalProgramInformationPage extends Vue {

    submission: FnolSubmission;
    masterNumberValidationRules: Array<string> = [];
    referenceIdValidationRules: Array<string> = [];
    validationUtils = new ValidationUtils();
    pageUtils = new PageUtils();
    certificateOfCoverage = 'Certificate Of Coverage';
    tenantProtectionAddendum = 'Tenant Protection Addendum';
    noRentalProgramDocumentation = 'No Rental Program Documentation';
    certificateDoc = false;
    addendumDoc = false;
    noDoc = false;

    created() {
      this.submission = this.$store.state.submission;
      this.setDocSelection(this.submission.rentalProgramInformation.rentalProgramDocumentationOption)
    }

    certificateShow(){
      return (this.submission.rentalProgramInformation.rentalProgramDocumentationOption === this.certificateOfCoverage)
    }
    certImageShow(){
      return (this.$vuetify.breakpoint.mdAndUp || this.submission.rentalProgramInformation.rentalProgramDocumentationOption === this.certificateOfCoverage)
    }

    addendumShow(){
      return (this.submission.rentalProgramInformation.rentalProgramDocumentationOption === this.tenantProtectionAddendum)
    }
    addendumImageShow(){
      return (this.$vuetify.breakpoint.mdAndUp || this.submission.rentalProgramInformation.rentalProgramDocumentationOption === this.tenantProtectionAddendum)
    }

    noDocsShow(){
      return (this.submission.rentalProgramInformation.rentalProgramDocumentationOption === this.noRentalProgramDocumentation)
    }

    masterNumberOnInput() {
      this.masterNumberValidationRules = this.runMasterNumberValidationRules();
    }

    referenceIdOnInput() {
      this.referenceIdValidationRules = this.runReferenceIdValidationRules()
    }

    runReferenceIdValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Reference ID is required',
        (v: any) => this.validationUtils.validateMaxLength(v, 100) || 'Max 100 characters',
        (v: any) => this.validationUtils.validateAlphaNumWithSpaces(v) || 'Cannot contain special characters'];
      return rules;
    }

    runMasterNumberValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Master Number is required',
        (v: any) => this.validationUtils.stringIsValidLength(v, 7) || 'Master Number must be 7 digits'];
      return rules;
    }


    disableNextButton() {
      switch (this.submission.rentalProgramInformation.rentalProgramDocumentationOption) {
        case this.certificateOfCoverage :
          return !this.validationUtils.stringIsValidLength(this.submission.rentalProgramInformation.masterNumber, 7);
        case this.tenantProtectionAddendum :
          return (this.submission.rentalProgramInformation.referenceId == null || this.submission.rentalProgramInformation.referenceId === '');
        case this.noRentalProgramDocumentation : return false;
        default :
          return true;
      }
    }

    radioGroupOnChange() {
      this.setDocSelection(this.submission.rentalProgramInformation.rentalProgramDocumentationOption);
      this.submission.rentalProgramInformation.masterNumber = null;
      this.submission.rentalProgramInformation.referenceId = null;
    }

    setDocSelection(radioVal:string){
      this.certificateDoc = false;
      this.addendumDoc = false;
      this.noDoc = false;

      if(radioVal === this.certificateOfCoverage){
        this.certificateDoc = true;
      }
      if(radioVal === this.tenantProtectionAddendum){
        this.addendumDoc = true;
      }
      if(radioVal === this.noRentalProgramDocumentation){
        this.noDoc = true;
      }
    }

    async navigateTo(pageName: string) {
      this.$store.dispatch('saveSubmission', this.submission).then(() => {
        if(this.pageUtils.isServersideValid(this.$store.state.ssValidationError)){
          window.scrollTo(0, 0);
          this.$router.push({
            name: pageName
          });
        }
      });
    }
  }


  import {Component, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {States} from "@/components/models/typelists/States";
  import {DateUtils} from "@/components/utils/date-utils";
  import CancelClaimModal from "@/views/CancelClaimModal.vue";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {UiUtils} from "@/components/utils/ui-utils";
  import {PageUtils} from "@/components/utils/page-utils/page-utils";
  import { IsoValidation } from "@/components/utils/iso-validation";

  @Component({
    components: {CancelClaimModal}
  })
  export default class StorageSelection extends Vue {

    windowWidth: number = 0;
    occupancyStartDatePicker: Boolean = false;
    occupancyEndDatePicker: Boolean = false;
    formattedOccupancyStartDate: string;
    formattedOccupancyEndDate: string;
    submission: FnolSubmission = new FnolSubmission();
    dateUtils: DateUtils = new DateUtils();
    uiUtils: UiUtils = new UiUtils();
    errorSnackbar: Boolean = false;
    stateList: Array<string> = Object.values(States);
    pageUtils = new PageUtils();

    // Rules arrays
    facilityNameValidationRules: Array<string> = [];
    facilityUnitNumberValidationRules: Array<string> = [];
    addressLine1ValidationRules: Array<string> = [];
    cityValidationRules: Array<string> = [];
    stateValidationRules: Array<string> = [];
    zipValidationRules: Array<string> = [];
    validationUtils: ValidationUtils = new ValidationUtils();
    occupancyStartDateValidationRules: Array<string> = [];
    occupancyEndDateValidationRules: Array<string> = [];
    storageValidationFlag: Boolean = false;
    isoValidationUtils: IsoValidation = new IsoValidation();

    created() {
      this.submission = this.$store.state.submission;
      this.windowWidth = window.innerWidth;

      if (this.submission.storageSelectionDetails != null)
      {
        if (this.submission.storageSelectionDetails.occupancyStartDate != null)
        {
          this.formattedOccupancyStartDate = this.dateUtils.formatDateForDisplay(this.submission.storageSelectionDetails.occupancyStartDate);
        }
        if (this.submission.storageSelectionDetails.occupancyEndDate != null)
        {
          this.formattedOccupancyEndDate = this.dateUtils.formatDateForDisplay(this.submission.storageSelectionDetails.occupancyEndDate);
        }
      }

    }

    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    }

    async navigateTo(pageName: string) {
        if (pageName === 'tenant-information') {
          await this.runValidationRules();
          if (!this.$store.state.storageSelectionIsValid) {
            window.scrollTo(0, 0);
            this.errorSnackbar = true;
            return;
          } else {
            this.$store.dispatch('saveSubmission', this.submission).then(() => {
              if(this.pageUtils.isServersideValid(this.$store.state.ssValidationError)) {
                window.scrollTo(0, 0);
                this.$router.push({
                  name: pageName
                });
              }
            });
          }
        } else {
          this.$store.dispatch('saveSubmission', this.submission).then(() => {
            window.scrollTo(0, 0);
            this.$router.push({
              name: pageName
            });
          });
        }

    }

    facilityNameOnInput(){
      this.facilityNameValidationRules = this.runFacilityNameOnInputValidationRules();
    }

    runFacilityNameOnInputValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Facility Name is required',
        (v: any) => !!v && this.isoValidationUtils.isValid(v, true) || 'The name entered is invalid',
        (v: any) => this.validationUtils.validateMaxLength(v, 60) || 'Max 60 characters',
        (v: any) => this.validationUtils.validateAlphaNumWithSpaceChars(v) || 'Cannot contain special characters'];
      return rules;
    }

    occupancyStartDateOnInput() {
      this.formattedOccupancyStartDate = this.dateUtils.formatDateForDisplay(this.submission.storageSelectionDetails.occupancyStartDate);
      this.occupancyStartDatePicker = false;
      this.occupancyStartDateValidationRules = this.runOccupancyStartDateValidationRules()
    }

    enteredOccupancyStartDateOnInput() {
      this.submission.storageSelectionDetails.occupancyStartDate = this.dateUtils.parseFormattedDate(this.formattedOccupancyStartDate);
      this.occupancyStartDatePicker = false;
      this.occupancyStartDateValidationRules = this.runOccupancyStartDateValidationRules()
    }

    occupancyEndDateOnInput() {
      if (null != this.submission.storageSelectionDetails.occupancyEndDate) {
        this.formattedOccupancyEndDate = this.dateUtils.formatDateForDisplay(this.submission.storageSelectionDetails.occupancyEndDate);
        this.occupancyEndDatePicker = false;
        this.occupancyEndDateValidationRules = this.runOccupancyEndDateValidationRules()
      }
    }

    enteredOccupancyEndDateOnInput() {
      this.submission.storageSelectionDetails.occupancyEndDate = this.dateUtils.parseFormattedDate(this.formattedOccupancyEndDate);
      this.occupancyEndDatePicker = false;
      this.occupancyEndDateValidationRules = this.runOccupancyEndDateValidationRules()
    }

    facilityUnitNumberOnInput(){
      this.facilityUnitNumberValidationRules = this.runFacilityUnitNumberOnInputValidationRules()
    }

    // triggerInput(id:string, event:Event){
    //   document.getElementById(id).dispatchEvent(event);
    // }

    async runValidationRules(){
      this.storageValidationFlag = true;
      await this.validationUtils.triggerBlur('facility-input')
      await this.validationUtils.triggerBlur('facility-address-line-1-input')
      await this.validationUtils.triggerBlur('facility-city-input')
      await this.validationUtils.triggerBlur('facility-state-select')
      await this.validationUtils.triggerBlur('facility-zip-input')
      await this.validationUtils.triggerBlur('facility-unit-number-input')
      await this.validationUtils.triggerBlur('occupancy-start-date-input')

      if(this.formattedOccupancyEndDate != "" && this.formattedOccupancyEndDate != null) {
        await this.validationUtils.triggerBlur('occupancy-end-date-input')
        this.occupancyEndDateValidationRules = this.runOccupancyEndDateValidationRules()
      }
      this.facilityNameValidationRules = this.runFacilityNameOnInputValidationRules();
      this.addressLine1ValidationRules = this.runAddressLine1OnInputValidationRules();
      this.cityValidationRules = this.runCityOnInputValidationRules();
      this.stateValidationRules = this.runStateOnInputValidationRules();
      this.zipValidationRules = this.runZipOnInputValidationRules();
      this.facilityUnitNumberValidationRules = this.runFacilityUnitNumberOnInputValidationRules();
      this.occupancyStartDateValidationRules = this.runOccupancyStartDateValidationRules()

    }

    storageStateErrorMessage() {
      if (this.storageValidationFlag) {
        if (this.submission.storageSelectionDetails.facilityState === null || this.submission.storageSelectionDetails.facilityState === '') {
          return "Facility State is required"
        } else {
          return null;
        }
      }
      return null;
    }

    runOccupancyStartDateValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Occupancy Start Date is required',
        (v: any) => this.validationUtils.validateDateNotPastOrFuture(v) || 'Please enter a present or past date',
        (v: any) => this.validationUtils.validateDateNotBeforeAnotherDate(v, '01/01/1900') || 'Year must be greater than 1900',
        (v: any) => this.validationUtils.validateDateIsSameOrBeforeAnotherDate(v, this.formattedOccupancyEndDate) || 'Start Date must be before End Date',
        (v: any) => this.validationUtils.validateValidDate(v) || 'Please enter a valid date',
        (v: any) => this.validationUtils.validateDateIsValidLength(v) || 'Please enter a valid date'
      ];
      return rules;

    }

    runOccupancyEndDateValidationRules(): Array<any> {
      let rules: Array<any> = [];
      if(this.formattedOccupancyEndDate != "" && this.formattedOccupancyEndDate != null){
        rules = [
          (v: any) => this.validationUtils.validateDateNotBeforeAnotherDate(v, '01/01/1900') || 'Year must be greater than 1900',
          (v: any) => this.validationUtils.validateDateIsSameOrAfterAnotherDate(v, this.formattedOccupancyStartDate) || 'End Date must be after Start Date',
          (v: any) => this.validationUtils.validateValidDate(v) || 'Please enter a valid date',
          (v: any) => this.validationUtils.validateDateIsValidLength(v) || 'Please enter a valid date'
      ]
      }
      return rules;
    }

    runFacilityUnitNumberOnInputValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Storage Unit Number is required',
        (v: any) => this.validationUtils.validateMaxLength(v, 10) || 'Max 10 characters',
        (v: any) => this.validationUtils.validateAlphaNumWithSpaceChars(v) || 'Please enter a valid unit number'] ;
      return rules;
    }

    addressLine1OnInput(){
      this.addressLine1ValidationRules = this.runAddressLine1OnInputValidationRules()
    }

    runAddressLine1OnInputValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Facility Address is required',
        (v: any) => !!v && this.isoValidationUtils.isValid(v, true) || 'The address entered is invalid',
        (v: any) => this.validationUtils.validateMaxLength(v, 60) || 'Max 60 characters',
        (v: any) => this.validationUtils.validateAddress(v) || 'Please enter a valid address'];
      return rules;
    }

    cityOnInput(){
      this.cityValidationRules = this.runCityOnInputValidationRules();
    }

    runCityOnInputValidationRules(): Array<any>{
      let rules = [
        (v: any ) => !!v || 'Facility City is required',
        (v: any) => this.validationUtils.validateCityCounty(v) || "No numbers or special characters allowed",
        (v: any) => !!v && this.isoValidationUtils.isValid(v, true) || 'The city entered is invalid',
        (v: any) => this.validationUtils.validateMaxLength(v, 60) || 'Max 60 characters'];
      return rules;
    }

    stateOnInput(){
      if (null != this.submission.storageSelectionDetails.facilityState) {
        this.submission.storageSelectionDetails.facilityStateCode = this.uiUtils.getStateCode(this.submission.storageSelectionDetails.facilityState);
      }
      this.stateValidationRules = this.runStateOnInputValidationRules();
    }

    runStateOnInputValidationRules(): Array<any>{
      let rules = [
        (v: any ) => !!v || 'Facility State is required'];
      return rules;
    }

    zipOnInput(){
      this.zipValidationRules = this.runZipOnInputValidationRules();
    }

    runZipOnInputValidationRules(): Array<any>{
      let rules = [
        (v: any ) => !!v || 'Facility Zip is required',
        (v: any) => this.validationUtils.validateUSAPostalCode(v) || 'Please enter a valid zip code'];
      return rules;
    }

    clearOccupancyEndDates()
    {
      this.submission.storageSelectionDetails.occupancyEndDate = null;
      this.formattedOccupancyEndDate = null;
    }
  }

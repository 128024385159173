

import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/components/models/FnolSubmission";
import {UiUtils} from "@/components/utils/ui-utils";

@Component
export default class TheftReviewAndSubmitPage extends Vue {

  @Prop()
  submission: FnolSubmission;

  uiUtils = new UiUtils();

}



  import Component from "vue-class-component";
  import {Prop, Vue} from "vue-property-decorator";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {MoldOrMildewValidationUtils} from "@/components/utils/loss-cause-question-validations/mold-or-mildew-validation-utils";
  import {MoldOrMildewDetailedLossCause} from "@/components/models/typelists/loss-cause/MoldOrMildewDetailedLossCause";
  import {$enum} from "ts-enum-util";

  @Component
  export default class MoldOrMildewSpecificQuestionsPage extends Vue {

    @Prop()
    submission: FnolSubmission;

    lossDescriptionValidationRules: Array<string> = [];
    locationDamageValidationRules: Array<string> = [];
    numberOfFloorsValidationRules: Array<string> = [];
    floorOfStorageUnitValidationRules: Array<string> = [];
    moldOrMildewDetailLossCauseValidationRules: Array<string> = [];
    validationUtils: ValidationUtils = new ValidationUtils();
    moldOrMildewValidationUtils: MoldOrMildewValidationUtils = new MoldOrMildewValidationUtils();
    moldOrMildewLossCauseList: Array<string> = Object.values(MoldOrMildewDetailedLossCause);

    created() {
      // Load rules arrays at page creation
      this.lossDescriptionValidationRules = this.moldOrMildewValidationUtils.runLossDescriptionValidation();
      this.numberOfFloorsValidationRules = this.moldOrMildewValidationUtils.runNumberOfFloorsValidation();
      this.floorOfStorageUnitValidationRules = this.moldOrMildewValidationUtils.runFloorOfStorageUnitValidation();
      this.locationDamageValidationRules = this.moldOrMildewValidationUtils.runLocationDamageValidation();
      this.moldOrMildewDetailLossCauseValidationRules = this.moldOrMildewValidationUtils.runDetailLossCauseValidationRules();
    }

    moldOrMildewDetailsLossCauseOnInput() {
      this.moldOrMildewValidationUtils.runDetailLossCauseValidationRules();
      if (this.submission.lossDetails.detailedLossCause == null) {
        this.submission.lossDetails.detailedLossCauseCode = null;
        return;
      }
      this.submission.lossDetails.detailedLossCauseCode =
        $enum(MoldOrMildewDetailedLossCause).getKeyOrThrow(this.submission.lossDetails.detailedLossCause);
    }
  }



  import Component from "vue-class-component";
  import {Vue} from "vue-property-decorator";
  import {UiUtils} from "@/components/utils/ui-utils";

  @Component
  export default class MobileStepper extends Vue {
    uiUtils: UiUtils = new UiUtils();

  }

import {Vue} from "vue-property-decorator";

export class IsoValidation extends Vue {
    isValid(value: string, isRequired : boolean ) {
        if (!value && !isRequired) {
            return true
        }
        if(value != null) {
            const result = !this.isoValues.some(element => {
                return element.toUpperCase().trim() === value.toUpperCase().trim()
            })
            return result;
        }
        return true
    }

    isoValues = [
        "                   N/A",
        "                N/A",
        "              N/A",
        "            N/A",
        "          N/A",
        "        N/A",
        "       N/A",
        "       NONE",
        "      N/A",
        "      NONE",
        "     N/A",
        "     NONE",
        "    N/A",
        "    NONE",
        "   N/A",
        "   NONE",
        "  N/A",
        "  NONE",
        " AKA",
        " N/A",
        " NONE",
        "'",
        "\"",
        "AKA",
        "DBA",
        "N/A",
        "NA",
        "NONE",
        "SE BY",
        "›",
        ".",
        "............",
        "..............................",
        "...,..",
        "...,...",
        "..,..",
        ".,",
        ".,.",
        "³",
        "*",
        "**",
        "***",
        "****",
        "*****",
        "******",
        "*******",
        "********",
        "*********",
        "**********",
        "***********",
        "************",
        "*************",
        "**************",
        "***************",
        "****************",
        "*****************",
        "******************",
        "*******************",
        "********************",
        "*********************",
        "**********************",
        "***********************",
        "************************",
        "*************************",
        "**************************",
        "***************************",
        "****************************",
        "*****************************",
        "******************************",
        "*******************************",
        "********************************",
        "*********************************",
        "**********************************",
        "***********************************",
        "************************************",
        "*************************************",
        "**************************************",
        "***************************************",
        "****************************************",
        "*****************************************",
        "******************************************",
        "*******************************************",
        "********************************************",
        "*********************************************",
        "**********************************************",
        "***********************************************",
        "************************************************",
        "*************************************************",
        "**************************************************",
        "***********PARKED",
        "**********PARKED",
        "*********PARKED",
        "********PARKED",
        "*****PARKED",
        "*****PARKED*",
        "****PARKED",
        "****PARKED**",
        "***PARKED",
        "**PARKED",
        "**PARKED**",
        "**PARKED**,",
        "*PARKED",
        "*PARKED*",
        "*REQUESTED",
        "-",
        "--",
        "---",
        "----",
        "-----",
        "------",
        "-------",
        "--------",
        "---------",
        "----------",
        "-----------",
        "------------",
        "-------------",
        "--------------",
        "---------------",
        "----------------",
        "-----------------",
        "------------------",
        "-------------------",
        "--------------------",
        "---------------------",
        "----------------------",
        "-----------------------",
        "------------------------",
        "-------------------------",
        "--------------------------",
        "---------------------------",
        "----------------------------",
        "-----------------------------",
        "------------------------------",
        "---------NON------------",
        "/",
        ",DR",
        ",PAARKED",
        ",PARKED",
        ",PARKED,&",
        ",PARKED,IN",
        ",PARKEED",
        ",PARRKED",
        ",POARKED",
        ",UNK-UNOCCUP",
        ",UNKNOWN",
        ",UNOCCUPIED,",
        ",UNOCUPIED",
        ",UNOCUPIED,",
        "?",
        "?,",
        "????????????",
        "??????????????????????????????",
        "`",
        "'",
        "',",
        "",
        "'',",
        "unk",
        "unk.",
        "unkn",
        "unknown",
        "A KA",
        "A/K/A",
        "AA",
        "AAAA",
        "AAAAA",
        "AAAAAA",
        "AAAAAAA",
        "AAAAAAAA",
        "AAAAAAAAA",
        "AAAAAAAAAA",
        "AAAAAAAAAAA",
        "AAAAAAAAAAAA",
        "AAAAAAAAAAAAA",
        "AAAAAAAAAAAAAA",
        "AAAAAAAAAAAAAAA",
        "AAAAAAAAAAAAAAAA",
        "AAAAAAAAAAAAAAAAA",
        "AAAAAAAAAAAAAAAAAA",
        "AAAAAAAAAAAAAAAAAAA",
        "AAAAAAAAAAAAAAAAAAAA",
        "ABC123",
        "AKA",
        "AKA  SLIM",
        "AKA LACHO",
        "AKA SNOW",
        "ALL",
        "ALSO",
        "AND SONS,",
        "AS",
        "ATTORNEY NONE",
        "ATTORNEY-NONE",
        "ATTY",
        "AUSENTE",
        "BB",
        "BBB",
        "BBBB",
        "BBBBB",
        "BBBBBB",
        "BBBBBBB",
        "BBBBBBBB",
        "BBBBBBBBB",
        "BBBBBBBBBB",
        "BBBBBBBBBBB",
        "BBBBBBBBBBBB",
        "BBBBBBBBBBBBB",
        "BBBBBBBBBBBBBB",
        "BBBBBBBBBBBBBBB",
        "BBBBBBBBBBBBBBBB",
        "BBBBBBBBBBBBBBBBB",
        "BBBBBBBBBBBBBBBBBB",
        "BBBBBBBBBBBBBBBBBBB",
        "BBBBBBBBBBBBBBBBBBBB",
        "BY",
        "BY***V*Y",
        "CAR,UNOCCUPIED",
        "CC",
        "CCC",
        "CCCC",
        "CCCCC",
        "CCCCCC",
        "CCCCCCC",
        "CCCCCCCC",
        "CCCCCCCCC",
        "CCCCCCCCCC",
        "CCCCCCCCCCC",
        "CCCCCCCCCCCC",
        "CCCCCCCCCCCCC",
        "CCCCCCCCCCCCCC",
        "CCCCCCCCCCCCCCC",
        "CCCCCCCCCCCCCCCC",
        "CCCCCCCCCCCCCCCCC",
        "CCCCCCCCCCCCCCCCCC",
        "CCCCCCCCCCCCCCCCCCC",
        "CCCCCCCCCCCCCCCCCCCC",
        "CLAIMANT",
        "CLMNT\"S VEH WAS ALSO",
        "CO,",
        "CORP,",
        "CORPORATION,",
        "CTR,",
        "D B A",
        "DA",
        "DBA",
        "DD",
        "DDD",
        "DDDD",
        "DDDDD",
        "DDDDDD",
        "DDDDDDD",
        "DDDDDDDD",
        "DDDDDDDDD",
        "DDDDDDDDDD",
        "DDDDDDDDDDD",
        "DDDDDDDDDDDD",
        "DDDDDDDDDDDDD",
        "DDDDDDDDDDDDDD",
        "DDDDDDDDDDDDDDD",
        "DDDDDDDDDDDDDDDD",
        "DDDDDDDDDDDDDDDDD",
        "DDDDDDDDDDDDDDDDDD",
        "DDDDDDDDDDDDDDDDDDD",
        "DDDDDDDDDDDDDDDDDDDD",
        "DESCONOCIDO",
        "DIVORCED",
        "DO,UNOCCUPIED",
        "DON T KNOW",
        "DR",
        "DR UNK",
        "DRV",
        "DRVR",
        "Et al",
        "Etal",
        "EE",
        "EEE",
        "EEEE",
        "EEEEE",
        "EEEEEE",
        "EEEEEEE",
        "EEEEEEEE",
        "EEEEEEEEE",
        "EEEEEEEEEE",
        "EEEEEEEEEEE",
        "EEEEEEEEEEEE",
        "EEEEEEEEEEEEE",
        "EEEEEEEEEEEEEE",
        "EEEEEEEEEEEEEEE",
        "EEEEEEEEEEEEEEEE",
        "EEEEEEEEEEEEEEEEE",
        "EEEEEEEEEEEEEEEEEE",
        "EEEEEEEEEEEEEEEEEEE",
        "EEEEEEEEEEEEEEEEEEEE",
        "ET AL",
        "ETAL",
        "EXT",
        "FF",
        "FFF",
        "FFFF",
        "FFFFF",
        "FFFFFF",
        "FFFFFFF",
        "FFFFFFFF",
        "FFFFFFFFF",
        "FFFFFFFFFF",
        "FFFFFFFFFFF",
        "FFFFFFFFFFFF",
        "FFFFFFFFFFFFF",
        "FFFFFFFFFFFFFF",
        "FFFFFFFFFFFFFFF",
        "FFFFFFFFFFFFFFFF",
        "FFFFFFFFFFFFFFFFF",
        "FFFFFFFFFFFFFFFFFF",
        "FFFFFFFFFFFFFFFFFFF",
        "FFFFFFFFFFFFFFFFFFFF",
        "GG",
        "GGG",
        "GGGG",
        "GGGGG",
        "GGGGGG",
        "GGGGGGG",
        "GGGGGGGG",
        "GGGGGGGGG",
        "GGGGGGGGGG",
        "GGGGGGGGGGG",
        "GGGGGGGGGGGG",
        "GGGGGGGGGGGGG",
        "GGGGGGGGGGGGGG",
        "GGGGGGGGGGGGGGG",
        "GGGGGGGGGGGGGGGG",
        "GGGGGGGGGGGGGGGGG",
        "GGGGGGGGGGGGGGGGGG",
        "GGGGGGGGGGGGGGGGGGG",
        "GGGGGGGGGGGGGGGGGGGG",
        "HH",
        "HHH",
        "HHHH",
        "HHHHH",
        "HHHHHH",
        "HHHHHHH",
        "HHHHHHHH",
        "HHHHHHHHH",
        "HHHHHHHHHH",
        "HHHHHHHHHHH",
        "HHHHHHHHHHHH",
        "HHHHHHHHHHHHH",
        "HHHHHHHHHHHHHH",
        "HHHHHHHHHHHHHHH",
        "HHHHHHHHHHHHHHHH",
        "HHHHHHHHHHHHHHHHH",
        "HHHHHHHHHHHHHHHHHH",
        "HHHHHHHHHHHHHHHHHHH",
        "HHHHHHHHHHHHHHHHHHHH",
        "HIRED CAR",
        "II",
        "III",
        "IIII",
        "IIIII",
        "IIIIII",
        "IIIIIII",
        "IIIIIIII",
        "IIIIIIIII",
        "IIIIIIIIII",
        "IIIIIIIIIII",
        "IIIIIIIIIIII",
        "IIIIIIIIIIIII",
        "IIIIIIIIIIIIII",
        "IIIIIIIIIIIIIII",
        "IIIIIIIIIIIIIIII",
        "IIIIIIIIIIIIIIIII",
        "IIIIIIIIIIIIIIIIII",
        "IIIIIIIIIIIIIIIIIII",
        "IIIIIIIIIIIIIIIIIIII",
        "IN,",
        "INFO",
        "JJ",
        "JJJ",
        "JJJJ",
        "JJJJJ",
        "JJJJJJ",
        "JJJJJJJ",
        "JJJJJJJJ",
        "JJJJJJJJJ",
        "JJJJJJJJJJ",
        "JJJJJJJJJJJ",
        "JJJJJJJJJJJJ",
        "JJJJJJJJJJJJJ",
        "JJJJJJJJJJJJJJ",
        "JJJJJJJJJJJJJJJ",
        "JJJJJJJJJJJJJJJJ",
        "JJJJJJJJJJJJJJJJJ",
        "JJJJJJJJJJJJJJJJJJ",
        "JJJJJJJJJJJJJJJJJJJ",
        "JJJJJJJJJJJJJJJJJJJJ",
        "JR",
        "JR.",
        "KDRV",
        "KEK BY",
        "KK",
        "KKK",
        "KKKK",
        "KKKKK",
        "KKKKKK",
        "KKKKKKK",
        "KKKKKKKK",
        "KKKKKKKKK",
        "KKKKKKKKKK",
        "KKKKKKKKKKK",
        "KKKKKKKKKKKK",
        "KKKKKKKKKKKKK",
        "KKKKKKKKKKKKKK",
        "KKKKKKKKKKKKKKK",
        "KKKKKKKKKKKKKKKK",
        "KKKKKKKKKKKKKKKKK",
        "KKKKKKKKKKKKKKKKKK",
        "KKKKKKKKKKKKKKKKKKK",
        "KKKKKKKKKKKKKKKKKKKK",
        "LL",
        "LLL",
        "LLLL",
        "LLLLL",
        "LLLLLL",
        "LLLLLLL",
        "LLLLLLLL",
        "LLLLLLLLL",
        "LLLLLLLLLL",
        "LLLLLLLLLLL",
        "LLLLLLLLLLLL",
        "LLLLLLLLLLLLL",
        "LLLLLLLLLLLLLL",
        "LLLLLLLLLLLLLLL",
        "LLLLLLLLLLLLLLLL",
        "LLLLLLLLLLLLLLLLL",
        "LLLLLLLLLLLLLLLLLL",
        "LLLLLLLLLLLLLLLLLLL",
        "LLLLLLLLLLLLLLLLLLLL",
        "MASTER AS",
        "MASTER MFG",
        "MASTER,",
        "MD",
        "MISS",
        "MM",
        "MMM",
        "MMMM",
        "MMMMM",
        "MMMMMM",
        "MMMMMMM",
        "MMMMMMMM",
        "MMMMMMMMM",
        "MMMMMMMMMM",
        "MMMMMMMMMMM",
        "MMMMMMMMMMMM",
        "MMMMMMMMMMMMM",
        "MMMMMMMMMMMMMM",
        "MMMMMMMMMMMMMMM",
        "MMMMMMMMMMMMMMMM",
        "MMMMMMMMMMMMMMMMM",
        "MMMMMMMMMMMMMMMMMM",
        "MMMMMMMMMMMMMMMMMMM",
        "MMMMMMMMMMMMMMMMMMMM",
        "MR",
        "MR & MRS.",
        "MR AND MRS",
        "MR AND MRS.",
        "MR.",
        "MR. & MRS",
        "MR. & MRS.",
        "MR. AND MRS",
        "MR. AND MRS.",
        "MRS",
        "MRS.",
        "MS",
        "MS.",
        "N & A",
        "N & A INC",
        "N /A",
        "N A",
        "N./A",
        "N.A.",
        "N-A",
        "N/A",
        "N/A.",
        "N/L",
        "N/S",
        "None",
        "NA",
        "NA NA",
        "NA.",
        "NA,",
        "NA,NA",
        "NAME",
        "NF",
        "NF,",
        "NF,/",
        "NF,/,NF",
        "NF,NF",
        "NN",
        "NNN",
        "NNNN",
        "NNNNN",
        "NNNNNN",
        "NNNNNNN",
        "NNNNNNNN",
        "NNNNNNNNN",
        "NNNNNNNNNN",
        "NNNNNNNNNNN",
        "NNNNNNNNNNNN",
        "NNNNNNNNNNNNN",
        "NNNNNNNNNNNNNN",
        "NNNNNNNNNNNNNNN",
        "NNNNNNNNNNNNNNNN",
        "NNNNNNNNNNNNNNNNN",
        "NNNNNNNNNNNNNNNNNN",
        "NNNNNNNNNNNNNNNNNNN",
        "NNNNNNNNNNNNNNNNNNNN",
        "NO",
        "NO DRIVER(PARKED)",
        "NO TAG",
        "NO TIENE",
        "NON OWNED",
        "NONE RETAINED",
        "NONE RETAINED AT THIS TIME",
        "NONE,",
        "NONE,,",
        "NONE,AS,PARKED",
        "NONE,NA,",
        "NONE,NONE",
        "NONE,NONE,",
        "NONE,PARKED",
        "NONE,PARKED,",
        "NONE,UNOCCUPIED",
        "NONEYET",
        "NONE1",
        "NONE2",
        "NONE3",
        "NOT AVAILABL",
        "NOT AVAILABLE",
        "NOT AVAILABLE AT THIS TIME",
        "NOT FURNISHED",
        "NOT KNOWN",
        "NOT KNOWN AT",
        "NOT KNOWN AT THIS TIME",
        "NOT KNWN",
        "NOT LISTED A",
        "NOT LISTED AT THIS TIME",
        "NOT OCCUPIED",
        "NOT PROVIDED",
        "NOT STATED",
        "NOT SURE",
        "NOT-KNOWN",
        "NOTAVAIL",
        "NOTAVAILABLE",
        "NOTKNOWN",
        "NOTPROVIDED",
        "NOTSURE",
        "N\\A",
        "NS",
        "NX",
        "OFFICE,",
        "ON",
        "OO",
        "OOO",
        "OOOO",
        "OOOOO",
        "OOOOOO",
        "OOOOOOO",
        "OOOOOOOO",
        "OOOOOOOOO",
        "OOOOOOOOOO",
        "OOOOOOOOOOO",
        "OOOOOOOOOOOO",
        "OOOOOOOOOOOOO",
        "OOOOOOOOOOOOOO",
        "OOOOOOOOOOOOOOO",
        "OOOOOOOOOOOOOOOO",
        "OOOOOOOOOOOOOOOOO",
        "OOOOOOOOOOOOOOOOOO",
        "OOOOOOOOOOOOOOOOOOO",
        "OOOOOOOOOOOOOOOOOOOO",
        "PAARKED,",
        "PARKED",
        "PARKED/UNOCCUPIED",
        "PARKED,",
        "PARKED,NONE",
        "PARKEED,",
        "PARRKED,",
        "PASTOR,",
        "PO BOX",
        "POARKED,",
        "POLICE",
        "PP",
        "PPP",
        "PPPP",
        "PPPPP",
        "PPPPPP",
        "PPPPPPP",
        "PPPPPPPP",
        "PPPPPPPPP",
        "PPPPPPPPPP",
        "PPPPPPPPPPP",
        "PPPPPPPPPPPP",
        "PPPPPPPPPPPPP",
        "PPPPPPPPPPPPPP",
        "PPPPPPPPPPPPPPP",
        "PPPPPPPPPPPPPPPP",
        "PPPPPPPPPPPPPPPPP",
        "PPPPPPPPPPPPPPPPPP",
        "PPPPPPPPPPPPPPPPPPP",
        "PPPPPPPPPPPPPPPPPPPP",
        "PRIVATE DOCT",
        "QQ",
        "QQQ",
        "QQQQ",
        "QQQQQ",
        "QQQQQQ",
        "QQQQQQQ",
        "QQQQQQQQ",
        "QQQQQQQQQ",
        "QQQQQQQQQQ",
        "QQQQQQQQQQQ",
        "QQQQQQQQQQQQ",
        "QQQQQQQQQQQQQ",
        "QQQQQQQQQQQQQQ",
        "QQQQQQQQQQQQQQQ",
        "QQQQQQQQQQQQQQQQ",
        "QQQQQQQQQQQQQQQQQ",
        "QQQQQQQQQQQQQQQQQQ",
        "QQQQQQQQQQQQQQQQQQQ",
        "QQQQQQQQQQQQQQQQQQQQ",
        "REQ UNK",
        "REQ/UNK",
        "REQD",
        "REQUIRED",
        "REQUIRED UNKNOWN",
        "REQUIRED/UNKNOWN",
        "REQUNK",
        "REQUNK00000000000",
        "RR",
        "RRR",
        "RRRR",
        "RRRRR",
        "RRRRRR",
        "RRRRRRR",
        "RRRRRRRR",
        "RRRRRRRRR",
        "RRRRRRRRRR",
        "RRRRRRRRRRR",
        "RRRRRRRRRRRR",
        "RRRRRRRRRRRRR",
        "RRRRRRRRRRRRRR",
        "RRRRRRRRRRRRRRR",
        "RRRRRRRRRRRRRRRR",
        "RRRRRRRRRRRRRRRRR",
        "RRRRRRRRRRRRRRRRRR",
        "RRRRRRRRRRRRRRRRRRR",
        "RRRRRRRRRRRRRRRRRRRR",
        "\\",
        "SAME",
        "SAME AS SSN",
        "SAME,SAME,",
        "SAMEASSSN",
        "SE BU",
        "SE BY",
        "SEE,",
        "SEE,MR",
        "SR",
        "SR.",
        "SS",
        "SSS",
        "SSSS",
        "SSSSS",
        "SSSSSS",
        "SSSSSSS",
        "SSSSSSSS",
        "SSSSSSSSS",
        "SSSSSSSSSS",
        "SSSSSSSSSSS",
        "SSSSSSSSSSSS",
        "SSSSSSSSSSSSS",
        "SSSSSSSSSSSSSS",
        "SSSSSSSSSSSSSSS",
        "SSSSSSSSSSSSSSSS",
        "SSSSSSSSSSSSSSSSS",
        "SSSSSSSSSSSSSSSSSS",
        "SSSSSSSSSSSSSSSSSSS",
        "SSSSSSSSSSSSSSSSSSSS",
        "T A",
        "Temp Tag",
        "TBD",
        "TEMP TAG",
        "TO,",
        "TT",
        "TTTT",
        "TTTTT",
        "TTTTTT",
        "TTTTTTT",
        "TTTTTTTT",
        "TTTTTTTTT",
        "TTTTTTTTTT",
        "TTTTTTTTTTT",
        "TTTTTTTTTTTT",
        "TTTTTTTTTTTTT",
        "TTTTTTTTTTTTTT",
        "TTTTTTTTTTTTTTT",
        "TTTTTTTTTTTTTTTT",
        "TTTTTTTTTTTTTTTTT",
        "TTTTTTTTTTTTTTTTTT",
        "TTTTTTTTTTTTTTTTTTT",
        "TTTTTTTTTTTTTTTTTTTT",
        "Unk",
        "Unk.",
        "Unknown",
        "UK",
        "UKNOWN",
        "UKNOWN,",
        "UN",
        "UNk",
        "UNATTENDED P",
        "UNAVAILABLE",
        "UNDETERMINED",
        "UNIDENTIFIED",
        "UNJ",
        "UNK",
        "UNK AT THIS",
        "UNK AT THIS TIME",
        "UNK UNK",
        "UNK YET",
        "UNK.",
        "UNK-PARKED T",
        "UNK-UNOCCUPI",
        "UNK,",
        "UNK,MR",
        "UNK,UNK",
        "UNK,UNK,",
        "UNK,YET,",
        "UNKN",
        "UNKN.",
        "UNKN.,ATTY,",
        "UNKNONW",
        "UNKNOWN",
        "UNKNOWN AT T",
        "UNKNOWN AT THIS",
        "UNKNOWN AT THIS TIME",
        "UNKNOWN PARK",
        "UNKNOWN 01",
        "UNKNOWN 02",
        "UNKNOWN 03",
        "UNKNOWN.",
        "UNKNOWN,.",
        "UNKNOWN,ATTY,",
        "UNKNOWN,MR",
        "UNKNOWN,NA",
        "UNKNOWN,NONE",
        "UNKNOWN,PARK",
        "UNKNOWN,UNKN",
        "UNKNOWN,UNKNOWN,",
        "UNKNOWNUNKNOWNUNKN",
        "UNKNOWN10",
        "UNKNWON",
        "UNKONW",
        "UNKWN",
        "UNKXXXXXXXXXXXXXXXXX",
        "UNK00",
        "UNK00000000000000000",
        "UNK123",
        "UNLICENSED",
        "UNNOCCUPIED,",
        "UNNOCCUPIEDI",
        "UNOCCUPIED",
        "UNOCCUPIED,",
        "UNOCCUPIED,PARKED",
        "UNOCCUPPIED",
        "UNOCCUPPIED,",
        "UNOCUPIED",
        "UNOCUPIED,",
        "UNSURE",
        "UU",
        "UUU",
        "UUUU",
        "UUUUU",
        "UUUUUU",
        "UUUUUUU",
        "UUUUUUUU",
        "UUUUUUUUU",
        "UUUUUUUUUU",
        "UUUUUUUUUUU",
        "UUUUUUUUUUUU",
        "UUUUUUUUUUUUU",
        "UUUUUUUUUUUUUU",
        "UUUUUUUUUUUUUUU",
        "UUUUUUUUUUUUUUUU",
        "UUUUUUUUUUUUUUUUU",
        "UUUUUUUUUUUUUUUUUU",
        "UUUUUUUUUUUUUUUUUUU",
        "UUUUUUUUUUUUUUUUUUUU",
        "VALIDNAMETOBEENTERED",
        "VALUETOBEENTERED",
        "VECH",
        "VEH",
        "VERAK BY",
        "VHE",
        "VV",
        "VVV",
        "VVVV",
        "VVVVV",
        "VVVVVV",
        "VVVVVVV",
        "VVVVVVVV",
        "VVVVVVVVV",
        "VVVVVVVVVV",
        "VVVVVVVVVVV",
        "VVVVVVVVVVVV",
        "VVVVVVVVVVVVV",
        "VVVVVVVVVVVVVV",
        "VVVVVVVVVVVVVVV",
        "VVVVVVVVVVVVVVVV",
        "VVVVVVVVVVVVVVVVV",
        "VVVVVVVVVVVVVVVVVV",
        "VVVVVVVVVVVVVVVVVVV",
        "VVVVVVVVVVVVVVVVVVVV",
        "WAS",
        "WITNESS",
        "WW",
        "WWW",
        "WWWW",
        "WWWWW",
        "WWWWWW",
        "WWWWWWW",
        "WWWWWWWW",
        "WWWWWWWWW",
        "WWWWWWWWWW",
        "WWWWWWWWWWW",
        "WWWWWWWWWWWW",
        "WWWWWWWWWWWWW",
        "WWWWWWWWWWWWWW",
        "WWWWWWWWWWWWWWW",
        "WWWWWWWWWWWWWWWW",
        "WWWWWWWWWWWWWWWWW",
        "WWWWWWWWWWWWWWWWWW",
        "WWWWWWWWWWWWWWWWWWW",
        "WWWWWWWWWWWWWWWWWWWW",
        "XX",
        "XXX",
        "XXXX",
        "XXXXX",
        "XXXXXX",
        "XXXXXXX",
        "XXXXXXXX",
        "XXXXXXXXX",
        "XXXXXXXXXX",
        "XXXXXXXXXXX",
        "XXXXXXXXXXXX",
        "XXXXXXXXXXXXX",
        "XXXXXXXXXXXXXX",
        "XXXXXXXXXXXXXXX",
        "XXXXXXXXXXXXXXXX",
        "XXXXXXXXXXXXXXXXX",
        "XXXXXXXXXXXXXXXXXX",
        "XXXXXXXXXXXXXXXXXXX",
        "XXXXXXXXXXXXXXXXXXXX",
        "YY",
        "YYY",
        "YYYY",
        "YYYYY",
        "YYYYYY",
        "YYYYYYY",
        "YYYYYYYY",
        "YYYYYYYYY",
        "YYYYYYYYYY",
        "YYYYYYYYYYY",
        "YYYYYYYYYYYY",
        "YYYYYYYYYYYYY",
        "YYYYYYYYYYYYYY",
        "YYYYYYYYYYYYYYY",
        "YYYYYYYYYYYYYYYY",
        "YYYYYYYYYYYYYYYYY",
        "YYYYYYYYYYYYYYYYYY",
        "YYYYYYYYYYYYYYYYYYY",
        "YYYYYYYYYYYYYYYYYYYY",
        "ZZ",
        "ZZZ",
        "ZZZZ",
        "ZZZZZ",
        "ZZZZZZ",
        "ZZZZZZZ",
        "ZZZZZZZZ",
        "ZZZZZZZZZ",
        "ZZZZZZZZZZ",
        "ZZZZZZZZZZZ",
        "ZZZZZZZZZZZZ",
        "ZZZZZZZZZZZZZ",
        "ZZZZZZZZZZZZZZ",
        "ZZZZZZZZZZZZZZZ",
        "ZZZZZZZZZZZZZZZZ",
        "ZZZZZZZZZZZZZZZZZ",
        "ZZZZZZZZZZZZZZZZZZ",
        "ZZZZZZZZZZZZZZZZZZZ",
        "ZZZZZZZZZZZZZZZZZZZZ",
        "00000000000",
        "1",
        "11",
        "111UNKNOWN1111111",
        "111UNKNOWN11111111",
        "12*",
        "123*",
        "123UNK",
        "1234*",
        "12345*",
        "123456*",
        "1234567*",
        "12345678*",
        "123456789*",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "“",
        "”",
        "\""
    ]
}
export class StorageFacility {
  facilityName!: string;
  facilityAddress!: string;
  facilityCity!: string;
  facilityStates!: Array<string>;
  facilityZip!: string;
  tenantStartDate!: string;
  policyNumber!: string;
  policySymbol!: string;
}
 

  import {Component, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";

  @Component
  export default class ConfirmationPage extends Vue {

    submission = new FnolSubmission();

    contactPhoneNumber = '877-589-6846'

    created() {
      this.submission = this.$store.state.submission;
    }

    startNewClaim() {
      this.$store.dispatch('startNewSubmission').then(() => {
        this.$router.push({
          name: 'rental-program-information'
        });
      });
    }
  }

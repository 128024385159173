import {Vue} from "vue-property-decorator";
import moment from "moment"

export class DateUtils extends Vue {

  // DATA MODEL TO UI
  formatDateForDisplay(date: string): string {
    return date ? moment(date).format('MM/DD/YYYY') : ''
  }

  // UI TO DATA MODEL
  parseFormattedDate(date: string): string {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  }

  formatTimeForDisplay(time: string): string {
    return time ? moment(time).format('LT') : ''
  }

  formatTimeForDisplayReview(date:string, time: string): string {
    if(time === null || time === ''){
      return '';
    }else{
    return date + " " + time ? moment(date + " " + time).format('LT') : ''
    }
  }

  trimSecFromTime(time: string): string{
    if(time != null){
      return time.substr(0,5);
    }
  }
}

import axios, {AxiosInstance} from "axios";


const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
});

export default axiosInstance;


  import {Prop, Vue} from "vue-property-decorator";
  import Component from "vue-class-component";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {StorageUnitAccess} from "@/components/models/typelists/StorageUnitAccess";
  import {TheftValidation} from "@/components/utils/loss-cause-question-validations/theft-validation";
  import {DateUtils} from "@/components/utils/date-utils";
  import {$enum} from "ts-enum-util";
  import {TheftDetailedLossCause} from "@/components/models/typelists/loss-cause/TheftDetailedLossCause";
  import {ValidationUtils} from "@/components/utils/validation-utils";

  @Component
  export default class TheftSpecificQuestionsPage extends Vue {

    @Prop()
    submission: FnolSubmission;

    storageUnitAccessList: Array<string> = Object.values(StorageUnitAccess);
    whatTypeOfLockUsedList: Array<string> = ["Disc Lock", "Pad Lock", "Combination Lock"];
    theftNotificationDatePicker: Boolean = false;
    formattedTheftNotificationDate: string = "";
    dateUtils = new DateUtils();
    theftValidationUtils = new TheftValidation();
    validationUtils = new ValidationUtils();
    theftDetailLossCauseList: Array<string> = Object.values(TheftDetailedLossCause);

    // Rules arrays
    lossDescriptionValidationRules: Array<string> = [];
    notificationDateValidationRules: Array<string> = [];
    howNotifiedValidationRules: Array<string> = [];
    otherStorageUnitsAffectedValidationRules: Array<string> = [];
    ceilingTypeValidationRules: Array<string> = [];
    wallsExtendValidationRules: Array<string> = [];
    howStorageAccessedValidationRules: Array<string> = [];
    closestCameraValidationRules: Array<string> = [];
    authorizedUsersValidationRules: Array<string> = [];
    passcodeRequiredValidationRules: Array<string> = [];
    policeDeptContactedValidationRules: Array<string> = [];
    policeReportNumberValidationRules: Array<string> = [];
    theftDetailLossCauseValidationRules: Array<string> = [];

    created() {
      // Load rules arrays at page creation
      this.lossDescriptionValidationRules = this.theftValidationUtils.runLossDescriptionValidation();
      this.notificationDateValidationRules = this.theftValidationUtils.runNotificationDateValidationRules();
      this.howNotifiedValidationRules = this.theftValidationUtils.runHowNotifiedValidation();
      this.otherStorageUnitsAffectedValidationRules = this.theftValidationUtils.runOtherStorageUnitsAffected();
      this.ceilingTypeValidationRules = this.theftValidationUtils.runCeilingTypeValidation();
      this.wallsExtendValidationRules = this.theftValidationUtils.runWallsExtendValidation();
      this.howStorageAccessedValidationRules = this.theftValidationUtils.runHowStorageUnitAccessedValidation();
      this.closestCameraValidationRules = this.theftValidationUtils.runClosestCameraValidation();
      this.authorizedUsersValidationRules = this.theftValidationUtils.runAuthorizedUsersValidation();
      this.passcodeRequiredValidationRules = this.theftValidationUtils.runPasscodeRequiredValidation();
      this.policeDeptContactedValidationRules = this.theftValidationUtils.runPoliceDeptContactedValidation();
      this.policeReportNumberValidationRules = this.theftValidationUtils.runPoliceReportNumberValidation();
      this.theftDetailLossCauseValidationRules = this.theftValidationUtils.runDetailLossCauseValidationRules();

      if(this.submission.lossDetails.theftSpecificQuestions.notificationDate != null){
        this.formattedTheftNotificationDate = this.dateUtils.formatDateForDisplay(this.submission.lossDetails.theftSpecificQuestions.notificationDate);
      }
    }

    theftNotificationDateOnInput() {
      this.formattedTheftNotificationDate = this.dateUtils.formatDateForDisplay(this.submission.lossDetails.theftSpecificQuestions.notificationDate);
      this.notificationDateValidationRules = this.theftValidationUtils.runNotificationDateValidationRules();
      this.theftNotificationDatePicker = false;
    }

    enteredTheftNotificationDateOnInput() {
      this.submission.lossDetails.theftSpecificQuestions.notificationDate = this.dateUtils.parseFormattedDate(this.formattedTheftNotificationDate);
      this.theftNotificationDatePicker = false;
      this.notificationDateValidationRules = this.theftValidationUtils.runNotificationDateValidationRules();
    }

    lossDetailsCauseOnInput() {
      this.theftValidationUtils.runDetailLossCauseValidationRules();
      if (this.submission.lossDetails.detailedLossCause == null) {
        this.submission.lossDetails.detailedLossCauseCode = null;
        return;
      }
      this.submission.lossDetails.detailedLossCauseCode =
        $enum(TheftDetailedLossCause).getKeyOrThrow(this.submission.lossDetails.detailedLossCause);
    }

  }

import {Vue} from "vue-property-decorator";
import {$enum} from "ts-enum-util";
import {States} from "@/components/models/typelists/States";
import {StateCodes} from "@/components/models/typelists/StateCodes";
import {Suffixes} from "@/components/models/typelists/Suffixes";

export class UiUtils extends Vue {

  checkNullBlankUndefined(testVal: string) {
    if (testVal === null || testVal === "" || typeof testVal === 'undefined') {
      return "N/A";
    }
    return testVal;
  }

  testForTrim(fieldValue: string): string{
    const field = (fieldValue != null && fieldValue.length != 0)?fieldValue.trim():null;

    if(field != null && field.length === 0){
      return null;
    }else{
      return field;
    }
  }


  convertBooleanToYesNo(value: Boolean): string {
    if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  formatCurrencyForDisplay(currency: number): string {
    if (currency !== null && currency !== undefined) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(parseFloat(currency.toString()));
    } else {
      return '';
    }
  }

  getStateCode(state: string) : string{
    return $enum(States).getKeyOrThrow(state);
  }

  getSuffixCode(suffix: string) : string{
    return $enum(Suffixes).getKeyOrThrow(suffix);
  }

}



  import Component from "vue-class-component";
  import {Prop, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {Status} from "@/components/models/Status";

  @Component
  export default class DeleteInventoryModal extends Vue {

    @Prop()
    submission: FnolSubmission;

    @Prop()
    index: number;

    created() {
    }

    yesButton() {
      this.$store.state.showDeleteInventoryModal = false;
      this.submission.inventoryInformationList.splice(this.index, 1)
      this.$store.dispatch('saveSubmission', this.$store.state.submission).then(() => {
        });

    }

    noButton(){
      this.$store.state.showDeleteInventoryModal = false;
    }

  }

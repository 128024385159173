export enum WaterSource {
    'Air conditioning leak' = 'Air conditioning leak',
    'Drain back up' = 'Drain back up',
    'Flooding' = 'Flooding',
    'Leak from unit above' = 'Leak from unit above',
    'Other' = 'Other',
    'Pipe leak' = 'Pipe leak',
    'Roof leak' = 'Roof leak',
    'Seeping from walls or floor' = 'Seeping from walls or floor',
    'Sewer back up' = 'Sewer back up',
    'Surface water' = 'Surface water'
}

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "landing-page",
      // props: (route) => ({ submissionId: route.query.id, action:route.query.action, page:route.query.page }),
      component: () =>
        import(/* webpackChunkName: "landing-page" */ "../views/landing-page.vue")
    },
    {
      path: "/landing-page",
      name: "landing-page",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "landing-page" */ "../views/landing-page.vue")
    },
    {
      path: "/access-agreement",
      name: "access-agreement",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
          import(/* webpackChunkName: "access-agreement" */ "../views/access-agreement.vue")
    },
    {
      path: "/rental-program-information",
      name: "rental-program-information",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
          import(/* webpackChunkName: "rental-program-information" */ "../views/rental-program-information.vue")
    },
    {
      path: "/storage-selection",
      name: "storage-selection",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
          import(/* webpackChunkName: "storage-selection" */ "../views/storage-selection.vue")
    },
    {
      path: "/tenant-information",
      name: "tenant-information",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
          import(/* webpackChunkName: "tenant-information" */ "../views/tenant-information.vue")
    },
    {
      path: "/loss-details",
      name: "loss-details",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
          import(/* webpackChunkName: "loss-details" */ "../views/loss-details.vue")
    },
    {
      path: "/inventory-information",
      name: "inventory-information",
      component: () =>
        import(/* webpackChunkName: "inventory-information" */ "../views/inventory-information.vue")
    },
    {
      path: "/additional-information",
      name: "additional-information",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
          import(/* webpackChunkName: "additional-information" */ "../views/additional-information.vue")
    },
    {
      path: "/review-and-submit",
      name: "review-and-submit",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "review-and-submit" */ "../views/review-and-submit.vue")
    },
    {
      path: "/confirmation",
      name: "confirmation",
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
          import(/* webpackChunkName: "confirmation" */ "../views/confirmation.vue")
    }
  ]
});

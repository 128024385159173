
  import {Component, Vue} from "vue-property-decorator";

  @Component
  export default class AccessAgreement extends Vue {
    created() {
    }

    agreementAccepted() {
      console.log("User has agreed");
      this.$store.dispatch('startNewSubmission').then(() => {
        window.scrollTo(0, 0);
        this.$router.push({
          name: 'rental-program-information'
        });
      });
    }

    agreementDeclined() {
      // console.log("User has disagreed");
      this.$router.push({
        name: 'landing-page'
      });
    }
  }

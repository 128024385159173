import {Vue} from "vue-property-decorator";
import {ValidationUtils} from "../validation-utils"

export class FireSpecificValidationUtils extends Vue {

  validationUtils: ValidationUtils = new ValidationUtils();

  runLossDescriptionValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Loss Description is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'
    ];
    return rules;
  }

  runFireOriginateValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Where did the fire/smoke originate? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'
    ];
    return rules;
  }

  runExtentOfDamageValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Extent of damages to items? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters'
    ];
    return rules;
  }

  runDetailLossCauseValidationRules(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Detailed Loss Cause is required'];
    return rules;
  }
}

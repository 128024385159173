export class TenantInformation {
  tenantFirstName!: string;
  tenantLastName!: string;
  tenantAddressLine1!: string;
  tenantAddressLine2!: string;
  tenantState!: string;
  tenantCity!: string;
  tenantStateCode!: string;
  tenantZip!: string;
  tenantEmail!: string;
  tenantPhone!: string;
  areYouTheTenant!: Boolean;
  reporterFirstName!: string;
  reporterLastName!: string;
  reporterEmail!: string;
  reporterPhone!: string;
  reporterRelationship!: string;
  reporterSuffix!: string;
  reporterSuffixCode!: string;
  tenantSuffix!: string;
  tenantSuffixCode!: string;
}


  import {Component, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import CancelClaimModal from "@/views/CancelClaimModal.vue";
  import {InventoryInformation} from "@/components/models/InventoryInformation";
  import DeleteInventoryModal from "@/views/modals/DeleteInventoryModal.vue";
  import {UiUtils} from "@/components/utils/ui-utils";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {RegExUtils} from "@/components/utils/regex-utils";
  import {InventoryValidation} from "@/components/utils/page-validations/inventory-validation";
  import {PageUtils} from "@/components/utils/page-utils/page-utils";


  @Component({
    components: {DeleteInventoryModal, CancelClaimModal}
  })
  export default class InventoryInformationPage extends Vue {
    windowWidth: number = 0;
    submission: FnolSubmission = new FnolSubmission();
    inventoryItem: InventoryInformation = new InventoryInformation();
    yearList: Array<any>;
    showItemForm = false;
    disableDoneButton = true;
    inventoryItemIndex: number;
    errorSnackbar: Boolean = false;
    uiUtils = new UiUtils();
    regExUtils = new RegExUtils();
    pageUtils = new PageUtils();
    validationUtils: ValidationUtils = new ValidationUtils();
    inventoryValidation: InventoryValidation = new InventoryValidation();
    itemDescriptionValidationRules: Array<string> = [];

    created() {
      this.submission = this.$store.state.submission;
      this.windowWidth = window.innerWidth;
      this.populateYearList();
    }
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    }

    populateYearList() {
      const year = new Date().getFullYear();
      this.yearList = Array.from({length: year - 1900}, (value, index) => year - index);
    }

    toggleItemForm() {
      this.showItemForm = true;
      this.disableDoneButton = true;
    }

    hideItemForm() {
      this.showItemForm = false;
      this.disableDoneButton = true;
      this.inventoryItem = new InventoryInformation();
    }

    async doneButton() {
      this.submission.inventoryInformationList.push(this.inventoryItem);
      await this.$store.dispatch('saveSubmission', this.submission).then( () => {
        this.$store.commit("setSubmission", this.submission)
        if (this.pageUtils.isServersideValid(this.$store.state.ssValidationError)) {
          this.inventoryItem = new InventoryInformation();
          this.$store.state.saveItem = true;
          this.showItemForm = false;
        } else {
          this.hideItemForm();
        }
      })
    }

    async addAnotherItemButton() {
      this.submission.inventoryInformationList.push(this.inventoryItem);
      await this.$store.dispatch('saveSubmission', this.submission).then(() => {
        this.$store.commit("setSubmission", this.submission)
        this.inventoryItem = new InventoryInformation();
        this.$store.state.saveItem = true;
        this.toggleItemForm();
        this.itemDescriptionValidationRules = [];
      })
    }

    addInventoryItemOnKeyUp() {
      this.setDoneButton();
      this.itemDescriptionValidationRules = this.inventoryValidation.runItemDescriptionValidation();
    }

    addInventoryItemOnBlur() {
      this.itemDescriptionValidationRules = this.inventoryValidation.runItemDescriptionValidation();
    }

    showDeleteModal(index: number){
      this.$store.state.showDeleteInventoryModal = true;
      this.inventoryItemIndex = index
    }


    async navigateTo(pageName: string) {
      if (pageName === 'additional-information') {
        await this.triggerValidations();
        if (!this.$store.state.inventoryPageIsValid) {
          window.scrollTo(0, 0);
          this.errorSnackbar = true;
          return;
        }
      }
      this.$store.dispatch('saveSubmission', this.submission).then(() => {
       // if(this.pageUtils.checkServersideError(this.$store.state.ssValidationError)) {
          window.scrollTo(0, 0);
          this.$router.push({
            name: pageName
          });
      //  }
      });
    }

    setDoneButton(){
      if(this.showItemForm){
        this.disableDoneButton = true;
        this.disableDoneButton = this.inventoryValidation.isDoneButtonDisabled(this.inventoryItem.itemDescription)
      }
    }

    async triggerValidations() {
        this.itemDescriptionValidationRules = this.inventoryValidation.runItemDescriptionValidation();
    }

  }

import {Vue} from "vue-property-decorator";
import moment from "moment";
import {RegExUtils} from "../utils/regex-utils"

export class ValidationUtils extends Vue {
  validateDateNotPastOrFuture(selectedDate: string): Boolean {
    const selectedDateInput = new Date(selectedDate);
    const currentDate = new Date();
    if (selectedDateInput != null && selectedDateInput > currentDate) {
      return false;
    } else {
      return true;
    }
  }

  validateDateNotPastAnotherDate(date1: string, date2: string): Boolean {
    if (date2 == null || date2 == '') {
      return true;
    }

    const date1Moment = moment(date1, ['YYYY-MM-DD', 'MM/DD/YYYY]'])
    const date2Moment = moment(date2, ['YYYY-MM-DD', 'MM/DD/YYYY]'])

    return moment(date1Moment).isAfter(date2Moment);
  }

  validateDateIsBeforeAnotherDate(date1: string, date2: string): Boolean {
    if (date2 == null || date2 == '') {
      return true;
    }
    const date1Moment = moment(date1, ['YYYY-MM-DD', 'MM/DD/YYYY]'])
    const date2Moment = moment(date2, ['YYYY-MM-DD', 'MM/DD/YYYY]'])
    return moment(date1Moment).isBefore(date2Moment);
  }

  validateDateNotBeforeAnotherDate(selectedDate: string, testedDate: string): Boolean {
    if (testedDate == null || testedDate == '') {
      return true;
    }
    // selectedDate IS NOT before testedDate
    return !moment(selectedDate).isBefore(testedDate);
  }

  validateDateIsSameOrBeforeAnotherDate(date1: string, date2: string): Boolean {
    if (date2 == null || date2 == '') {
      return true;
    }
    const date1Moment = moment(date1, ['YYYY-MM-DD', 'MM/DD/YYYY]'])
    const date2Moment = moment(date2, ['YYYY-MM-DD', 'MM/DD/YYYY]'])
    return moment(date1Moment).isSameOrBefore(date2Moment);
  }

  validateDateIsSameOrAfterAnotherDate(selectedDate: string, testedDate: string): Boolean {
    if (testedDate == null || testedDate == '') {
      return true;
    }
    // selectedDate IS NOT before testedDate
    return moment(selectedDate).isSameOrAfter(testedDate);
  }

  validatePhoneNumberIsValidLength(phoneNumber: string): Boolean {
    if (phoneNumber == null || phoneNumber == '') {
      return true;
    }
    const parsedPhone = phoneNumber.replace('(', "")
      .replace('-', "").replace(')', "")
      .replace(" ", "");
    if (parsedPhone.length > 1 && parsedPhone.length == 10) {
      return true;
    } else {
      return false;
    }
  }

  validatePhoneNumberIsNotAllSameNumbers(phoneNumber: string): Boolean {
    if (phoneNumber == null || phoneNumber == '') {
      return true;
    }
    const parsedPhone = phoneNumber.replace('(', "")
        .replace('-', "").replace(')', "")
        .replace(" ", "");

    const regEx = new RegExp("^(\\d)(?!\\1+$)\\d{9}$");

    if (parsedPhone.length == 10) {
      return regEx.test(parsedPhone);
    }
  }

  validateEmailIsValidFormat(email: string): Boolean {
    if (email == null || email == "") {
      return true;
    }

    return RegExUtils.EMAIL.test(email);
  }

  validateSsnIsValidLength(ssn: string): Boolean {
    if (ssn == null || ssn == '') {
      return true;
    }

    const parsedSsn = ssn.replace("-", "").replace("-", "");

    if (parsedSsn.length > 1 && parsedSsn.length == 9) {
      return true;
    } else {
      return false;
    }
  }

  validateUsZipIsValidLength(zip: string): Boolean {
    if (zip == null || zip == '') {
      return true;
    }

    // Check if zip ends with a hyphen
    if(zip.slice(-1) == "-"){
      return false;
    }

    const parsedZip = zip.replace("-", "");

    if ((parsedZip.length > 1 &&
          parsedZip.length == 5) ||
            (parsedZip.length > 1 && parsedZip.length == 9)) {
      return true;
    } else {
      return false;
    }
  }

  validateMaxLength(string: string, length: number): Boolean {
    if (string == null || string == '' || string.length <= length) {
      return true;
    } else {
      return false;
    }
  }

  validateMinLength(string: string, length: number): Boolean {
    if (string == null || string == '' || string.length >= length) {
      return true;
    } else {
      return false;
    }
  }

  validateDateIsValidLength(date: string): Boolean {
    if (date == null || date == '') {
      return true;
    }

    const parsedDate = date.replace("/", "").replace("/", "");

    if (parsedDate.length > 1 && parsedDate.length == 8) {
      return true;
    } else {
      return false;
    }
  }

  validateValidDate(date: string): boolean {
    return moment(date, ["YYYY-MM-DD", "MM/DD/YYYY"], true).isValid();
  }

  lossCauseDetailsErrorMessage(detailedLossCause: string, flag:boolean){
    if (flag) {
      if (detailedLossCause === null ||  detailedLossCause === '') {
        return "Detailed Loss Cause is required"
      } else {
        return null;
      }
    }
    return null;
  }

  showRequiredErrorMessage(input:any, flag:boolean, errorMessage:string) {
    if (flag) {
      if (input === undefined || input === null ||  input === '') {
        return errorMessage
      } else {
        return null;
      }
    }
    return null;
  }

  // REMOVE
  stringIsValidLength(input:string, length:number){
    if (input == null || input == '' || input.trim() == '') {
      return false;
    }

    if(input.length >0 && input.length === length){
      return true;
    }

    return false;
  }

  triggerEvent(id: string, event: Event) {
    document.getElementById(id).dispatchEvent(event);
  }

  async triggerBlur(id: string){
    await this.focusById(id);
    await this.blurById(id);
  }

  async focusById(id: string){
    document.getElementById(id).focus();

  }

  async blurById(id: string){
    document.getElementById(id).blur();
  }

  validateCityCounty(inputString: string) {
    return RegExUtils.CITY_COUNTY.test(inputString);
  }

  validateAlphaNumWithSpaces(fieldInput: string): boolean {
    return RegExUtils.ALPHA_NUM_WITH_SPACE.test(fieldInput)
  }

  validateAlphaWithSpaceChars(fieldInput: string): boolean {
    return RegExUtils.ALPHA_WITH_SPACE_CHARS.test(fieldInput)
}

  validateUSAPostalCode(fieldInput: string): boolean {
    return RegExUtils.USA_POSTAL_CODE.test(fieldInput)
  }

  validateAlphaNumWithSpaceChars(fieldInput: string): boolean {
    return RegExUtils.ALPHA_NUM_WITH_SPACE_CHARS.test(fieldInput)
  }

  validateAddress(fieldInput: string): boolean {
    return RegExUtils.ADDRESS.test(fieldInput)
  }

  validatePhoneNumber(fieldInput: string): boolean {
    return RegExUtils.PHONE_US_DOES_NOT_START_WITH_1_OR_0.test(fieldInput)
  }

  validateTextArea(fieldInput: string): boolean {
    return RegExUtils.TEXT_AREA.test(fieldInput)
  }
}

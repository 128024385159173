

import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/components/models/FnolSubmission";

@Component
export default class InsectOrRodentReviewAndSubmitPage extends Vue {

  @Prop()
  submission: FnolSubmission;

}


  import Vue from 'vue';
  import {Component} from "vue-property-decorator";
  import Header from './views/header.vue';
  import Footer from './views/footer.vue';
  import MobileStepper from "@/views/components/mobile-stepper.vue";
  import ProgressBar from "@/views/components/progress-bar.vue";

  @Component({
    components: {
      MobileStepper,
      ProgressBar,
      Header,
      Footer
    }
  })
  export default class App extends Vue {

  }

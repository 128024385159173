

import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import {ValidationUtils} from "@/components/utils/validation-utils";
import {FnolSubmission} from "@/components/models/FnolSubmission";
import {InsectRodentSpecificValidationUtils} from "@/components/utils/loss-cause-question-validations/insect-rodent-specific-validation-utils";
import {InsectOrRodentsLossCause} from "@/components/models/typelists/loss-cause/InsectOrRodentsLossCause";
import {$enum} from "ts-enum-util";

@Component
export default class InsectOrRodentSpecificQuestionsPage extends Vue {

  @Prop()
  submission: FnolSubmission;

  lossDescriptionValidationRules: Array<string> = [];
  typeOfInsectValidationRules: Array<string> = [];
  howItemsDamagedValidationRules: Array<string> = [];
  whatItemsDamagedValidationRules: Array<string> = [];
  detailLossCauseValidationRules: Array<string> = [];
  validationUtils: ValidationUtils = new ValidationUtils();
  insectRodentValidationUtils = new InsectRodentSpecificValidationUtils();
  insectOrRodentLossCauseList: Array<string> = Object.values(InsectOrRodentsLossCause);

  created() {
    // Load rules arrays at page creation
    this.lossDescriptionValidationRules = this.insectRodentValidationUtils.runLossDescriptionValidation();
    this.typeOfInsectValidationRules = this.insectRodentValidationUtils.runtypeOfInsectValidation();
    this.howItemsDamagedValidationRules = this.insectRodentValidationUtils.runHowItemsDamagedValidation();
    this.whatItemsDamagedValidationRules = this.insectRodentValidationUtils.runWhatItemsDamagedValidation();
    this.detailLossCauseValidationRules = this.insectRodentValidationUtils.runDetailLossCauseValidationRules();

  }

  insectOrRodentDetailsLossCauseOnInput() {
    this.insectRodentValidationUtils.runDetailLossCauseValidationRules();
    if (this.submission.lossDetails.detailedLossCause == null) {
      this.submission.lossDetails.detailedLossCauseCode = null;
      return;
    }
    this.submission.lossDetails.detailedLossCauseCode =
        $enum(InsectOrRodentsLossCause).getKeyOrThrow(this.submission.lossDetails.detailedLossCause);
  }
}


import {Component, Vue} from "vue-property-decorator";
import VueRecaptcha from "vue-recaptcha";

@Component({
  components: {
    'vue-recaptcha': VueRecaptcha
  }
})
  export default class LandingPage extends Vue {

  loadRecaptcha = false;
  isHuman = false;
  siteKey = '';
  isInternal = false;

    async created() {
      this.$store.commit('setIsAccessInternal', true)
      await this.$store.dispatch('getIsInternal')
      if (this.$store.state.isAccessInternal == true) {
        this.isInternal = true;
      }

      this.$store.commit('setIsRecaptchaTokenValid', false)
      this.$store.dispatch('getKey').then(() => {
        this.siteKey = this.$store.state.siteKey.key
        if(this.siteKey == "ACCEPTANCE-TEST-867-5309") {
          //disable recaptcha for acceptance tests
          this.loadRecaptcha = false;
          this.isHuman = true
        } else {
          this.loadRecaptcha = true
        }
      })
    }

    onCaptchaVerified(recaptchaToken: string) {
      this.$store.dispatch('verifyToken', recaptchaToken).then(() => {
        if(this.$store.state.isRecaptchaTokenValid == true) {
          this.isHuman = true;
        }
      });
    }

    onCaptchaExpired() {
      this.isHuman = false;
    }

  async reportLossCta() {
    window.scrollTo(0, 0);
    this.$router.push({
      name: 'access-agreement'
    });
  }

}

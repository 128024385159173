import {Vue} from "vue-property-decorator";
import {ValidationUtils} from "@/components/utils/validation-utils";
import {RegExUtils} from "@/components/utils/regex-utils";

export class InventoryValidation extends Vue {

  validationUtils: ValidationUtils = new ValidationUtils();
  regExUtils: RegExUtils = new RegExUtils();

  runItemDescriptionValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Item Description is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateMinLength(v, 3) || 'Min 3 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'
    ];
    return rules;
  }

  isDoneButtonDisabled(itemText: string){
    if(itemText == null){
      return true;
    }
    if(itemText.length >= 3 &&
      itemText.length <= 500){
      //  && this.regExUtils.alphaNumSpaceAndCharacters(itemText)){
      return false;
    }else{
      return true;
    }
  }

}

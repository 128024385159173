import {Vue} from "vue-property-decorator";
import {ValidationUtils} from "../validation-utils"

export class OtherSpecificValidation extends Vue {

  validationUtils: ValidationUtils = new ValidationUtils();

  runLossDescriptionValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Loss Description is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runAdditionalInfoValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Additional information about the loss  is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }
}

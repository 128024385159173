import axiosInstance from '@/components/utils/axios-instance'
import {FnolSubmission} from '@/components/models/FnolSubmission'
import {Vue} from 'vue-property-decorator'
import {InternalAdminData} from "@/components/models/InternalAdminData";


export class FnolApi extends Vue {

  async getKey(): Promise<Record<string, any>> {
    const response = await axiosInstance.request<Record<string, any>>({
      method: 'post',
      url: '/recaptcha/key',
      responseType: 'json'
    });
    return response.data;
  }

  async isTokenValid(token: string): Promise<boolean> {
    const response = await axiosInstance.request<boolean>({
      method: 'post',
      url: '/recaptcha/verify',
      data: token,
      responseType: 'json',
    });
    return response.data;
  }

  async isInternal(): Promise<boolean> {
    const response = await axiosInstance.request<boolean>({
      method: 'get',
      url: '/isInternal',
      responseType: 'json'
    });
    return response.data;
  }

  async getSubmission(id: string): Promise<FnolSubmission> {
    console.log("getting submission with id: " + id);

    const response = await axiosInstance.request<FnolSubmission>({
      method: 'post',
      url: '/fnol/' + id,
      responseType: 'json',
    });

    return response.data;
  }

  async createSubmission(fnolSubmission: FnolSubmission): Promise<FnolSubmission> {
    console.log("creating submission");
    const response = await axiosInstance.request<FnolSubmission>({
      method: 'post',
      url: '/fnol/create',
      data: fnolSubmission,
      responseType: 'json',
    });
    return response.data;
  }

  async updateSubmission(fnolSubmission: FnolSubmission): Promise<FnolSubmission> {
    console.log("updating submission id: " + fnolSubmission.submissionId);

    const response = await axiosInstance.request<FnolSubmission>({
      method: 'put',
      url: '/fnol/update',
      data: fnolSubmission,
      responseType: 'json',
    });

    return response.data;
  }

  async getInternalAdminData(): Promise<InternalAdminData> {
    console.log("getting InternalAdminData (CSR)");
    const response = await axiosInstance.request<InternalAdminData>({
      method: 'get',
      url: '/fnol/internaladmindata',
      responseType: 'json',
    });

    return response.data;
  }
}

export enum FireLossCause {
    '5001electriccurrents' = 	'Electric Currents',
    '5002irritantchemicalele' =	'Irritant or Chemical or Electrical',
    '5003fire' = 'Fire',
    '5004lightning' =	'Lightning',
    '5005firecausedbygpe' =	'Fire caused by gasoline powered equipment',
    '5006firebyautomobile' = 'Fire caused by automobile',
    '5007smokedamage' =	'Smoke Damage',
    '5008spontaneouscmbs'	= 'Spontaneous Combustion',
    '5099otherelefire' =	'Other Electrical or Fire'
}
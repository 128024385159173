

  import {Component, Vue} from "vue-property-decorator";
  import {States} from "@/components/models/typelists/States";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import CancelClaimModal from "@/views/CancelClaimModal.vue";
  import { UiUtils } from '@/components/utils/ui-utils';
  import {PageUtils} from "@/components/utils/page-utils/page-utils";
  import {Suffixes} from "@/components/models/typelists/Suffixes";
  import { CommonContact } from '@/components/models/CommonContact';
  import {IsoValidation} from "@/components/utils/iso-validation";

  @Component({
    components: {CancelClaimModal}
  })
  export default class TenantInfoPage extends Vue {
    windowWidth: number = 0;
    statesList: Array<string> = this.getStatesList();
    tenantFirstNameValidationRules: Array<string> = [];
    tenantLastNameValidationRules: Array<string> = [];
    tenantAddressValidationRules: Array<string> = [];
    tenantAddressLine2ValidationRules: Array<string> = [];
    tenantCityValidationRules: Array<string> = [];
    tenantStateValidationRules: Array<string> = [];
    tenantZipValidationRules: Array<string> = [];
    tenantEmailValidationRules: Array<string> = [];
    tenantPhoneValidationRules: Array<string> = [];
    reporterFirstNameValidationRules: Array<string> = [];
    reporterLastNameValidationRules: Array<string> = [];
    reporterEmailValidationRules: Array<string> = [];
    reporterPhoneValidationRules: Array<string> = [];
    reporterRelationshipValidationRules: Array<string> = [];
    validationUtils: ValidationUtils = new ValidationUtils();
    submission: FnolSubmission;
    errorSnackbar: Boolean = false;
    tenantValidationFlag = false;
    uiUtils: UiUtils = new UiUtils();
    pageUtils = new PageUtils();
    suffixesList: Array<string> = this.getSuffixesList();
    isoValidationUtils: IsoValidation = new IsoValidation();

    created() {
      console.log("tenant-information created called")
      this.submission = this.$store.state.submission;
      this.windowWidth = window.innerWidth;
      if(!this.submission.commonData.claimReporter) {
        this.submission.commonData.claimReporter = new CommonContact();
      }
      if(!this.submission.commonData.claimPreparer && !this.$store.state.isAccessInternal) {
        this.submission.commonData.claimPreparer = new CommonContact();
      }
    }

    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    }

    async navigateTo(pageName: string) {
      if (pageName === 'loss-details') {
        await this.triggerValidations();
        if (!this.$store.state.tenantPageIsValid) {
          window.scrollTo(0, 0);
          this.errorSnackbar = true;
          return;
        }
      }
      this.saveCommonData();
      this.$store.dispatch('saveSubmission', this.submission).then(() => {
        if(this.pageUtils.isServersideValid(this.$store.state.ssValidationError)) {
          window.scrollTo(0, 0);
          this.$router.push({
            name: pageName
          });
        }
      });
    }

    async triggerValidations() {

      this.tenantValidationFlag = true;

      await this.validationUtils.triggerBlur('tenant-first-name');
      await this.validationUtils.triggerBlur('tenant-last-name');
      await this.validationUtils.triggerBlur('tenant-address-1');
      await this.validationUtils.triggerBlur('tenant-city');
      await this.validationUtils.triggerBlur('tenant-state');
      await this.validationUtils.triggerBlur('tenant-zip');
      await this.validationUtils.triggerBlur('tenant-email');
      await this.validationUtils.triggerBlur('tenant-phone');

      this.tenantFirstNameValidationRules = this.runTenantFirstNameValidationRules();
      this.tenantLastNameValidationRules = this.runTenantLastNameValidationRules();
      this.tenantAddressValidationRules = this.runTenantAddressValidationRules();
      this.tenantCityValidationRules = this.runTenantCityValidationRules();
      this.tenantStateValidationRules = this.runTenantStateValidationRules();
      this.tenantZipValidationRules = this.runTenantZipValidationRules();
      this.tenantEmailValidationRules = this.runTenantEmailValidationRules();
      this.tenantPhoneValidationRules = this.runTenantPhoneValidationRules();

      if (this.submission.tenantInformation.areYouTheTenant === false) {
        await this.validationUtils.triggerBlur('reporter-first-name');
        await this.validationUtils.triggerBlur('reporter-last-name');
        await this.validationUtils.triggerBlur('reporter-email');
        await this.validationUtils.triggerBlur('reporter-phone');
        await this.validationUtils.triggerBlur('reporter-relationship');

        this.reporterFirstNameValidationRules = this.runReporterFirstNameValidationRules();
        this.reporterLastNameValidationRules = this.runReporterLastNameValidationRules();
        this.reporterEmailValidationRules = this.runReporterEmailValidationRules();
        this.reporterPhoneValidationRules = this.runReporterPhoneValidationRules();
        this.reporterRelationshipValidationRules = this.runReporterRelationValidationRules();
      }
    }

    saveCommonData() {
      if(this.submission.tenantInformation.areYouTheTenant) {
        //Claim Reporter
        this.submission.commonData.claimReporter.firstName = this.submission.tenantInformation.tenantFirstName;
        this.submission.commonData.claimReporter.lastName = this.submission.tenantInformation.tenantLastName;
        this.submission.commonData.claimReporter.phone = this.submission.tenantInformation.tenantPhone;
        this.submission.commonData.claimReporter.emailAddress = this.submission.tenantInformation.tenantEmail;

        //Claim Preparer
        if(!this.$store.state.isAccessInternal) {
          this.submission.commonData.claimPreparer.firstName = this.submission.tenantInformation.tenantFirstName;
          this.submission.commonData.claimPreparer.lastName = this.submission.tenantInformation.tenantLastName;
          this.submission.commonData.claimPreparer.phone = this.submission.tenantInformation.tenantPhone;
          this.submission.commonData.claimPreparer.emailAddress = this.submission.tenantInformation.tenantEmail;
        }
      }
      else {
        //Claim Reporter
        this.submission.commonData.claimReporter.firstName = this.submission.tenantInformation.reporterFirstName;
        this.submission.commonData.claimReporter.lastName = this.submission.tenantInformation.reporterLastName;
        this.submission.commonData.claimReporter.phone = this.submission.tenantInformation.reporterPhone;
        this.submission.commonData.claimReporter.emailAddress = this.submission.tenantInformation.reporterEmail;

        //Claim Preparer
        if(!this.$store.state.isAccessInternal) {
          this.submission.commonData.claimPreparer.firstName = this.submission.tenantInformation.reporterFirstName;
          this.submission.commonData.claimPreparer.lastName = this.submission.tenantInformation.reporterLastName;
          this.submission.commonData.claimPreparer.phone = this.submission.tenantInformation.reporterPhone;
          this.submission.commonData.claimPreparer.emailAddress = this.submission.tenantInformation.reporterEmail;
        }
      }
    }

    getStatesList(): Array<string> {
      return Object.values(States);
    }

    tenantFirstNameOnInput() {
      this.tenantFirstNameValidationRules = this.runTenantFirstNameValidationRules();
    }

    tenantLastNameOnInput() {
      this.tenantLastNameValidationRules = this.runTenantLastNameValidationRules();
    }

    tenantAddressOnInput() {
      this.tenantAddressValidationRules = this.runTenantAddressValidationRules();
    }

    tenantAddressLine2OnInput() {
      this.tenantAddressLine2ValidationRules = this.runTenantAddressLine2ValidationRules();
    }

    tenantCityOnInput() {
      this.tenantCityValidationRules = this.runTenantCityValidationRules();
    }

    tenantStateOnInput() {
      if (null != this.submission.tenantInformation.tenantState) {
        this.submission.tenantInformation.tenantStateCode = this.uiUtils.getStateCode(this.submission.tenantInformation.tenantState);
      }
      this.tenantStateValidationRules = this.runTenantStateValidationRules();
    }

    tenantZipOnInput() {
      this.tenantZipValidationRules = this.runTenantZipValidationRules();
    }

    tenantEmailOnInput() {
      this.submission.tenantInformation.tenantEmail  = this.uiUtils.testForTrim(this.submission.tenantInformation.tenantEmail);
      this.tenantEmailValidationRules = this.runTenantEmailValidationRules();
    }

    tenantPhoneOnInput() {
      this.tenantPhoneValidationRules = this.runTenantPhoneValidationRules();
    }

    reporterFirstNameOnInput() {
      this.reporterFirstNameValidationRules = this.runReporterFirstNameValidationRules();
    }

    reporterLastNameOnInput() {
      this.reporterLastNameValidationRules = this.runReporterLastNameValidationRules();
    }

    reporterSuffixOnSelect() {
      if (null != this.submission.tenantInformation.reporterSuffix) {
        this.submission.tenantInformation.reporterSuffixCode = this.uiUtils.getSuffixCode(this.submission.tenantInformation.reporterSuffix);
      }
    }

    tenantSuffixOnSelect() {
      if (null != this.submission.tenantInformation.tenantSuffix) {
        this.submission.tenantInformation.tenantSuffixCode = this.uiUtils.getSuffixCode(this.submission.tenantInformation.tenantSuffix);
      }
    }

    reporterEmailOnInput() {
      this.submission.tenantInformation.reporterEmail = this.submission.tenantInformation.reporterEmail.trim();
      this.reporterEmailValidationRules = this.runReporterEmailValidationRules();
    }

    reporterPhoneOnInput() {
      this.reporterPhoneValidationRules = this.runReporterPhoneValidationRules();
    }

    reporterRelationOnInput() {
      this.reporterRelationshipValidationRules = this.runReporterRelationValidationRules();
    }

    resetReporterValues() {
      this.submission.tenantInformation.reporterFirstName = null;
      this.submission.tenantInformation.reporterLastName = null;
      this.submission.tenantInformation.reporterEmail = null;
      this.submission.tenantInformation.reporterPhone = null;
      this.submission.tenantInformation.reporterRelationship = null;
      this.reporterFirstNameValidationRules = [];
      this.reporterLastNameValidationRules = [];
      this.reporterEmailValidationRules = [];
      this.reporterPhoneValidationRules = [];
      this.reporterRelationshipValidationRules = [];
    }

    runTenantFirstNameValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Tenant First Name is required',
        (v: any) => !!v && this.isoValidationUtils.isValid(v, true) || 'The name entered is invalid',
        (v: any) => this.validationUtils.validateMaxLength(v, 30) || 'Max 30 characters',
        (v: any) => this.validationUtils.validateAlphaWithSpaceChars(v) || 'Cannot contain numbers or special characters'];
      return rules;
    }

    runTenantLastNameValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Tenant Last Name is required',
        (v: any) => !!v && this.isoValidationUtils.isValid(v, true) || 'The name entered is invalid',
        (v: any) => this.validationUtils.validateMaxLength(v, 30) || 'Max 30 characters',
        (v: any) => this.validationUtils.validateAlphaWithSpaceChars(v) || 'Cannot contain numbers or special characters'];
      return rules;
    }

    runTenantAddressValidationRules(): Array<any> {
      let rules = [
        (v: any) => this.validationUtils.validateMaxLength(v, 60) || 'Max 60 characters',
        (v: any) => this.isoValidationUtils.isValid(v, false) || 'The address entered is invalid',
        (v: any) => this.validationUtils.validateAddress(v) || 'Cannot contain special characters'];
      return rules;
    }

    runTenantAddressLine2ValidationRules(): Array<any> {
      let rules = [
        (v: any) => this.validationUtils.validateMaxLength(v, 60) || 'Max 60 characters',
        (v: any) => this.isoValidationUtils.isValid(v, false) || 'The address entered is invalid',
        (v: any) => this.validationUtils.validateAddress(v) || 'Cannot contain special characters']
      return rules;
    }

    runTenantCityValidationRules(): Array<any> {
      let rules = [
        (v: any) => this.validationUtils.validateCityCounty(v) || "No numbers or special characters allowed.",
        (v: any) => this.isoValidationUtils.isValid(v, false) || 'The city entered is invalid',
        (v: any) => this.validationUtils.validateMaxLength(v, 60) || 'Max 60 characters'];
      return rules;
    }

    runTenantStateValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Tenant State is required'];
      return rules;
    }

    tenantStateErrorMessage() {
      if (this.tenantValidationFlag) {
        if (this.submission.tenantInformation.tenantState === null || this.submission.tenantInformation.tenantState === '') {
          return "Tenant State is required"
        } else {
          return null;
        }
      }
      return null;
    }

    areYouTheTenantErrorMessage() {
      if (this.tenantValidationFlag) {
        if (this.submission.tenantInformation.areYouTheTenant === null) {
          return "Are you the tenant? is required"
        } else {
          return null;
        }
      }
      return null;
    }

    runTenantZipValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Tenant Zip is required',
        (v: any) => this.validationUtils.validateUsZipIsValidLength(v) || 'Please enter a valid zip code',
        (v: any) => this.validationUtils.validateUSAPostalCode(v) || 'Please enter a valid zip code'];
      return rules;
    }

    runTenantEmailValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Tenant Email is required',
        (v: any) => this.validationUtils.validateEmailIsValidFormat(v) || 'Please enter a valid email',
        (v: any) => this.validationUtils.validateMaxLength(v, 50) || 'Max 50 characters'];
      return rules;
    }

    runTenantPhoneValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Tenant Phone is required',
        (v: any) => this.validationUtils.validatePhoneNumberIsValidLength(v) || 'Please enter a valid phone number',
        (v: any) => this.validationUtils.validatePhoneNumberIsNotAllSameNumbers(v) || 'Phone number may not be all same digits',
        (v: any) => this.validationUtils.validatePhoneNumber(v) || 'Phone number cannot start with 0 or 1'];

      return rules;
    }

    runReporterFirstNameValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'First Name is required',
        (v: any) => !!v && this.isoValidationUtils.isValid(v, true) || 'The name entered is invalid',
        (v: any) => this.validationUtils.validateMaxLength(v, 30) || 'Max 30 characters',
        (v: any) => this.validationUtils.validateAlphaWithSpaceChars(v) || 'Cannot contain numbers or special characters'];
      return rules;
    }

    runReporterLastNameValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Last Name is required',
        (v: any) => !!v && this.isoValidationUtils.isValid(v, true) || 'The name entered is invalid',
        (v: any) => this.validationUtils.validateMaxLength(v, 30) || 'Max 30 characters',
        (v: any) => this.validationUtils.validateAlphaWithSpaceChars(v) || 'Cannot contain numbers or special characters'];
      return rules;
    }

    runReporterEmailValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Email is required',
        (v: any) => this.validationUtils.validateEmailIsValidFormat(v) || 'Please enter a valid email',
        (v: any) => this.validationUtils.validateMaxLength(v, 50) || 'Max 50 characters'];
      return rules;
    }

    runReporterPhoneValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Phone is required',
        (v: any) => this.validationUtils.validatePhoneNumberIsValidLength(v) || 'Please enter a valid phone number',
        (v: any) => this.validationUtils.validatePhoneNumberIsNotAllSameNumbers(v) || 'Phone number may not be all same digits',
        (v: any) => this.validationUtils.validatePhoneNumber(v) || 'Phone number cannot start with 0 or 1'];
      return rules;
    }

    runReporterRelationValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Relationship to Tenant is required',
        (v: any) => this.validationUtils.validateMaxLength(v, 60) || 'Max 60 characters',
        (v: any) => this.validationUtils.validateAlphaNumWithSpaceChars(v) || 'Cannot contain special characters']
      return rules;
    }

    getSuffixesList(): Array<string> {
      return Object.values(Suffixes);
    }


  }


var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.$route.name === 'rental-program-information'
          || this.$route.name === 'storage-selection'
          || this.$route.name === 'tenant-information'
          || this.$route.name === 'loss-details'
          || this.$route.name === 'inventory-information'
          || this.$route.name === 'additional-information')?_c('v-row',{staticClass:"d-flex justify-center",attrs:{"data-cy":"progress-bar"}},[_c('v-col',{staticClass:"hidden-md-and-down",attrs:{"cols":"12","xl":"8","data-cy":"stepper-col"}},[_c('v-stepper',{staticClass:"stepper-background",attrs:{"alt-labels":"","data-cy":"stepper"}},[_c('v-stepper-header',{staticClass:"pl-4 pr-4",attrs:{"data-cy":"stepper-header"}},[_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"rentalInfoStep","step":"","editable":this.$route.name === 'rental-program-information',"edit-icon":"","complete":"","color":this.$route.name === 'rental-program-information' ? '#1976D2' : 'green'}},[_c('v-label',{staticClass:"pt-4"},[_vm._v("Rental Program Information")])],1),_vm._v(" "),_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"storageSelectionStep","step":"","editable":this.$route.name === 'storage-selection',"edit-icon":"","complete":this.$route.name === 'storage-selection'
                  || this.$route.name === 'tenant-information'
                  || this.$route.name === 'loss-details'
                  || this.$route.name === 'inventory-information'
                  || this.$route.name === 'additional-information',"color":this.$route.name === 'rental-program-information' ? '#ffffff' : this.$route.name === 'storage-selection' ? '#1976D2' : 'green'}},[_c('v-label',{staticClass:"pt-4",attrs:{"data-cy":"progress-bar-storage-select-label"}},[_vm._v("Storage Facility Location")])],1),_vm._v(" "),_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"tenantInformationStep","step":"","editable":this.$route.name === 'tenant-information',"edit-icon":"","complete":this.$route.name === 'tenant-information'
                  || this.$route.name === 'loss-details'
                  || this.$route.name === 'inventory-information'
                  || this.$route.name === 'additional-information',"color":this.$route.name === 'rental-program-information'
                  || this.$route.name === 'storage-selection' ? '#ffffff' : this.$route.name === 'tenant-information' ? '#1976D2' : 'green'}},[_c('v-label',{staticClass:"pt-4",attrs:{"data-cy":"progress-bar-tenant-information-label"}},[_vm._v("Tenant Information")])],1),_vm._v(" "),_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"lossDetailsStep","step":"","editable":this.$route.name === 'loss-details',"edit-icon":"","complete":this.$route.name === 'loss-details'
                  || this.$route.name === 'inventory-information'
                  || this.$route.name === 'additional-information',"color":this.$route.name === 'rental-program-information'
                  || this.$route.name === 'storage-selection'
                  || this.$route.name === 'tenant-information' ? '#ffffff' : this.$route.name === 'loss-details' ? '#1976D2' : 'green'}},[_c('v-label',{staticClass:"pt-4",attrs:{"data-cy":"progress-bar-loss-details-label"}},[_vm._v("Loss Details")])],1),_vm._v(" "),_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"inventoryInformationStep","step":"","editable":this.$route.name === 'inventory-information',"edit-icon":"","complete":this.$route.name === 'inventory-information'
                  ||this.$route.name === 'additional-information',"color":this.$route.name === 'rental-program-information'
                  || this.$route.name === 'storage-selection'
                  || this.$route.name === 'tenant-information'
                  || this.$route.name === 'loss-details' ? '#ffffff' : this.$route.name === 'inventory-information' ? '#1976D2' : 'green'}},[_c('v-label',{staticClass:"pt-4",attrs:{"data-cy":"progress-bar-inventory-information-label"}},[_vm._v("Inventory Information")])],1),_vm._v(" "),_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"additionalInformationStep","step":"","editable":_vm.$route.name === 'additional-information',"edit-icon":"","complete":this.$route.name === 'additional-information',"color":this.$route.name === 'rental-program-information'
                  || this.$route.name === 'storage-selection'
                  || this.$route.name === 'tenant-information'
                  || this.$route.name === 'loss-details'
                  || this.$route.name === 'inventory-information'
              ? '#ffffff' : this.$route.name === 'additional-information' ? '#1976d2' : 'green'}},[_c('v-label',{staticClass:"pt-4",attrs:{"data-cy":"progress-bar-add-information-label"}},[_vm._v("Additional Information")])],1)],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"hidden-md-and-down reportNumber",attrs:{"cols":"12","xl":"12"}},[_vm._v("\n    Report #:\n    "),_c('span',{attrs:{"data-cy":"progress-bar-report-number"}},[_vm._v(_vm._s(this.uiUtils.checkNullBlankUndefined(this.$store.state.submission.reportNumber)))])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
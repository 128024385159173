import {Vue} from "vue-property-decorator";
import {ValidationUtils} from "../validation-utils"

export class TheftValidation extends Vue {

  validationUtils: ValidationUtils = new ValidationUtils();

  runLossDescriptionValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Loss Description is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'
    ];
    return rules;
  }

  runNotificationDateValidationRules(): Array<any> {
    const rules = [
      (v: any) => !!v || 'When were you notified? is required',
      (v: any) => this.validationUtils.validateDateNotPastOrFuture(v) || 'Please enter a present or past date',
      (v: any) => this.validationUtils.validateDateNotBeforeAnotherDate(v, '01/01/1900') || 'Year must be greater than 1900',
      (v: any) => this.validationUtils.validateValidDate(v) || 'Please enter a valid date',
      (v: any) => this.validationUtils.validateDateIsValidLength(v) || 'Please enter a valid date'
    ];
    return rules;
  }

  runHowNotifiedValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'How were you notified? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runOtherStorageUnitsAffected(): Array<any> {
    const rules = [
      (v: any) => !!v || 'How many other storage units were affected? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 20) || 'Max 20 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runCeilingTypeValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Type of ceiling is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runWallsExtendValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Do the storage unit walls extend to the ceiling? is required']
    return rules;
  }

  runHowStorageUnitAccessedValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'How was the storage unit accessed? is required'
    ]
    return rules;
  }

  runClosestCameraValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'If facility has a surveillance system where is the closest camera? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runPasscodeRequiredValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Is a passcode required to enter the premises or building? is required'
    ]
    return rules;

  }

  runAuthorizedUsersValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Please list any authorized users to the storage unit is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runPoliceDeptContactedValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Name of Police Department Contacted is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runPoliceReportNumberValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Police Report Number is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 20) || 'Max 20 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runDetailLossCauseValidationRules(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Detailed Loss Cause is required'];
    return rules;
  }
}

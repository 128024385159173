import {Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/components/models/FnolSubmission";

export class PageUtils extends Vue {

  // @ts-ignore
  updateClaimSubmittedByInfoOnSubmit(submission : FnolSubmission) : FnolSubmission {
    if (!submission.tenantInformation.areYouTheTenant) {
      if (submission.tenantInformation.reporterFirstName != null) {
        submission.claimSubmitterFirstName = submission.tenantInformation.reporterFirstName;
      }
      if (submission.tenantInformation.reporterLastName != null) {
        submission.claimSubmitterLastName = submission.tenantInformation.reporterLastName;
      }
      if (submission.tenantInformation.reporterEmail != null) {
        submission.claimSubmitterEmail = submission.tenantInformation.reporterEmail;
      }
    } else {
      if (submission.tenantInformation.tenantFirstName != null) {
        submission.claimSubmitterFirstName = submission.tenantInformation.tenantFirstName;
      }
      if (submission.tenantInformation.tenantLastName != null) {
        submission.claimSubmitterLastName = submission.tenantInformation.tenantLastName;
      }
      if (submission.tenantInformation.tenantEmail != null) {
        submission.claimSubmitterEmail = submission.tenantInformation.tenantEmail;
      }
    }
  }

  isServersideValid(error: boolean){
    if(error){
     // alert("SUBMISSION ERROR!")
      return false;
    }else{
      return true;
    }
  }


}

import Vue from 'vue';
import Vuex from 'vuex';
import {FnolSubmission} from "@/components/models/FnolSubmission";
import {FnolService} from "@/services/fnol-service";
import {SearchDuplicateClaimResponse} from "@/components/models/SearchDuplicateClaimResponse";
import {StorageFacility} from "@/components/models/StorageFacility";
import {StorageSelectionDetails} from "@/components/models/StorageSelectionDetails";
import {Status} from "@/components/models/Status";
import router from "@/router/router"
import {CustomerServiceRep} from "@/components/models/CustomerServiceRep";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    submission: FnolSubmission,
    storageSelectionDetails: StorageSelectionDetails,
    redirect: String,
    showAddAreaOfBody: false,
    invalidFields: new Set(),
    storageSelectionIsValid: true,
    pendingSubmissions: Array<FnolSubmission>(),
    pendingSubmissionsFound: true,
    duplicateClaimResponse: SearchDuplicateClaimResponse,
    showCancelClaimModal: false,
    showDuplicateClaimModal: false,
    saveSnackbar: false,
    saveItem: false,
    inventoryItemIsValid: true,
    tenantPageIsValid: true,
    inventoryPageIsValid: true,
    addInfoPageIsValid: true,
    lossDetailsIsValid: true,
    theftSpecificQuestionsIsValid: true,
    facilityByName: StorageFacility,
    detailedLossCauseValidationFlag: false,
    validationFlag:false,
    siteKey: String,
    isAccessInternal: Boolean,
    isRecaptchaTokenValid: false,
    showDeleteInventoryModal:false,
    ssValidationError:false,
    customerServiceRepList: Array<CustomerServiceRep>()
  },
  actions: {
    async getKey({commit}){
      const service = new FnolService()
      await service.getKey().then(response => {
        commit('setKey', response)
      });
    },
    async verifyToken({commit}, token: string){
      const service = new FnolService()
      return service.isTokenValid(token).then(response => {
        commit('setIsRecaptchaTokenValid', response)
      });
    },
    async getSubmission({commit}, submissionId: string) {
      const service = new FnolService();
      console.log("retrieving storage fnol with ID: " + submissionId);
      return service.getFnol(submissionId).then(submission => {
        console.log("service returned get id:" + submission.submissionId);
        commit('setSubmission', submission)
      });
    },
    async getIsInternal({commit}) {
      const service = new FnolService()
      return service.isInternal().then(response => {
        commit('setIsAccessInternal', response)
      });
    },

    async saveSubmission({commit}, submission: FnolSubmission) {
      const service = new FnolService();
      console.log("saving fnol");
      return await service.saveFnol(submission).then(submission => {
        if(submission.submissionId === "ERROR"){
          commit("setValidationError", submission)
        }else{
          commit('setSubmission', submission);
        }
      });
    },

    async startNewSubmission({dispatch}): Promise<Boolean> {
      console.log("startNewSubmission:starting new fnol");
      const submission = new FnolSubmission();
      return await dispatch("saveSubmission", submission);
    },
    async getInternalAdminData({commit}){
      const service = new FnolService();
      console.log("calling getInternalAdminData for CSR");
      return await service.getInternalAdminData().then(data => {
        commit("setInternalAdminData", data)
      });
    },
    backToLanding({dispatch}, submission){
      if(submission.status === Status.IN_REVIEW){
        window.scrollTo(0, 0);
        router.push({
          name:'landing-page'
        });
      }
    }
  },
  mutations: {
    setSubmission(state, submission) {
      state.submission = submission
      state.ssValidationError = false;
    },
    setValidationError(state, submission){
      state.ssValidationError = true;
    },
    setPending(state, pendingSubmissions) {
      state.pendingSubmissions = pendingSubmissions;
    },
    setKey(state, response) {
      state.siteKey = response;
    },
    setIsRecaptchaTokenValid(state, response) {
      state.isRecaptchaTokenValid = response;
    },
    setIsAccessInternal(state, response) {
      state.isAccessInternal = response;
    },
    setInternalAdminData(state, data) {
      state.customerServiceRepList = [];
      if(data && data.customerServiceRepList){
          state.customerServiceRepList = data.customerServiceRepList;
      }
    }
  },
  getters: {
    getSubmission: state => {
      return state.submission;
    }
  }
})



  import {Component, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {DateUtils} from "@/components/utils/date-utils";
  import {LossCause} from "@/components/models/typelists/loss-cause/LossCause";
  import {$enum} from "ts-enum-util";
  import FireSpecificQuestionsPage from "@/views/loss-cause-question-components/FireSpecificQuestions.vue";
  import InsectOrRodentSpecificQuestionsPage
    from "@/views/loss-cause-question-components/InsectOrRodentSpecificQuestions.vue";
  import MoldOrMildewSpecificQuestionsPage
    from "@/views/loss-cause-question-components/MoldOrMildewSpecificQuestions.vue";
  import WaterSpecificQuestionsPage from "@/views/loss-cause-question-components/WaterSpecificQuestions.vue";
  import {WaterSpecificQuestions} from '@/components/models/WaterSpecificQuestions';
  import {MoldOrMildewSpecificQuestions} from "@/components/models/MoldOrMildewSpecificQuestions";
  import {InsectOrRodentSpecificQuestions} from "@/components/models/InsectOrRodentSpecificQuestions";
  import {FireSpecificQuestions} from '@/components/models/FireSpecificQuestions';
  import OtherSpecificQuestionsPage from "@/views/loss-cause-question-components/OtherSpecificQuestions.vue";
  import {OtherSpecificQuestions} from "@/components/models/OtherSpecificQuestions";
  import TheftSpecificQuestionsPage from "@/views/loss-cause-question-components/TheftSpecificQuestions.vue";
  import CancelClaimModal from "@/views/CancelClaimModal.vue";
  import {TheftSpecificQuestions} from "@/components/models/TheftSpecificQuestions";
  import {PageUtils} from "@/components/utils/page-utils/page-utils";

  @Component({
    components: {
      CancelClaimModal,
      OtherSpecificQuestions: OtherSpecificQuestionsPage,
      WaterSpecificQuestions: WaterSpecificQuestionsPage,
      InsectOrRodentSpecificQuestions: InsectOrRodentSpecificQuestionsPage,
      FireSpecificQuestions: FireSpecificQuestionsPage,
      MoldOrMildewSpecificQuestions: MoldOrMildewSpecificQuestionsPage,
      TheftSpecificQuestions: TheftSpecificQuestionsPage
    }
  })
  export default class LossDetailsPage extends Vue {
    windowWidth: number = 0;
    submission = new FnolSubmission();
    errorSnackbar: Boolean = false;
    validationUtils = new ValidationUtils();
    dateUtils = new DateUtils();
    dateOfDiscoveryPicker: Boolean = false;
    dateOfLossPicker: Boolean = false;
    timeOfDiscoveryPicker: Boolean = false;
    timeOfLossPicker: Boolean = false;
    lossCauseList: Array<string> = Object.values(LossCause);

    formattedDateOfDiscovery: string;
    formattedTimeOfDiscovery: string;
    dateOfDiscoveryValidationRules: Array<string> = [];
    formattedDateOfLoss: string;
    formattedTimeOfLoss: string;
    dateOfLossValidationRules: Array<string> = [];
    lossCauseValidationRules: Array<string> = [];
    lossOccurredValidationFlag: boolean = false;
    lossCauseValidationFlag: boolean = false;
    pageUtils = new PageUtils();
    lossCauseSelection: string;

    created() {
      this.submission = this.$store.state.submission;
      this.windowWidth = window.innerWidth;
      if(this.submission.lossDetails != null) {
        this.lossCauseSelection = this.submission.lossDetails.lossCause
        this.formatDatesAndTimeForDisplayOnLoad();
      }
    }
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    }

    formatDatesAndTimeForDisplayOnLoad(){
      if (this.submission.lossDetails.dateOfDiscovery != null){
        this.formattedDateOfDiscovery = this.dateUtils.formatDateForDisplay(this.submission.lossDetails.dateOfDiscovery);
      }

      if (this.submission.lossDetails.timeOfDiscovery != null){
        this.formattedTimeOfDiscovery = this.dateUtils.formatTimeForDisplay(this.submission.lossDetails.dateOfDiscovery + " " +
            this.submission.lossDetails.timeOfDiscovery);
      }

      if (this.submission.lossDetails.dateOfLoss != null){
        this.formattedDateOfLoss = this.dateUtils.formatDateForDisplay(this.submission.lossDetails.dateOfLoss);
      }

      if (this.submission.lossDetails.timeOfLoss != null){
        this.formattedTimeOfLoss = this.dateUtils.formatTimeForDisplay(this.submission.lossDetails.dateOfLoss + " " +
            this.submission.lossDetails.timeOfLoss);
      }
    }

    async triggerValidations(){
      this.$store.state.validationFlag = true;

      this.triggerLossDetailsValidations();
      if (this.submission.lossDetails.lossCause == 'Fire') {
       await this.triggerFireSpecificValidations();
      }
      if (this.submission.lossDetails.lossCause == 'Insect or Rodent') {
        await this.triggerInsectSpecificValidations();
      }
      if (this.submission.lossDetails.lossCause == 'Mold or Mildew') {
        await this.triggerMoldOrMildewSpecificValidations();
      }
      if (this.submission.lossDetails.lossCause == 'Theft') {
        await this.triggerTheftSpecificValidations();
      }
      if (this.submission.lossDetails.lossCause == 'Water') {
        await this.triggerWaterSpecificValidations();
      }
      if (this.submission.lossDetails.lossCause == 'Other') {
        await this.triggerOtherSpecificValidations();
      }
    }

  async triggerFireSpecificValidations(){
      let event = new Event('input');

      this.$store.state.detailedLossCauseValidationFlag = true;
      await this.triggerInput('fire-originate', event); // initiates object
      await this.validationUtils.triggerBlur('loss-description');
      await this.validationUtils.triggerBlur('fire-originate');
      await this.validationUtils.triggerBlur('extent-damage');
    }

   async triggerInsectSpecificValidations(){
     let event = new Event('input')
      this.$store.state.detailedLossCauseValidationFlag = true;
      await this.triggerInput('type-of-insect', event); // initiates object
      await this.validationUtils.triggerBlur('loss-description');
      await this.validationUtils.triggerBlur('type-of-insect');
      await this.validationUtils.triggerBlur('how-items-damaged');
      await this.validationUtils.triggerBlur('what-items-damaged');
    }

    async triggerMoldOrMildewSpecificValidations(){
      this.$store.state.detailedLossCauseValidationFlag = true;
      await this.validationUtils.triggerBlur('loss-description');
      await this.validationUtils.triggerBlur('location-damage');
      await this.validationUtils.triggerBlur('number-of-floors');
      await this.validationUtils.triggerBlur('unit-floor');
    }

    async triggerTheftSpecificValidations() {
      this.$store.state.detailedLossCauseValidationFlag = true;
      await this.validationUtils.triggerBlur('loss-description');
      await this.validationUtils.triggerBlur('theft-notification-date-input');
      await this.validationUtils.triggerBlur('how-notified-input');
      await this.validationUtils.triggerBlur('other-units-affected-input');
      await this.validationUtils.triggerBlur('ceiling-type-input');
      await this.validationUtils.triggerBlur('closest-camera-input');
      await this.validationUtils.triggerBlur('authorized-users-input');


      if (this.submission.lossDetails.theftSpecificQuestions.reportedToPolice) {
        await this.validationUtils.triggerBlur('police-dept-contacted-input');
        await this.validationUtils.triggerBlur('police-report-number-input');
      }

    }

   async triggerWaterSpecificValidations() {
      this.$store.state.detailedLossCauseValidationFlag = true;
      await this.validationUtils.triggerBlur('loss-description');
      if (this.submission.lossDetails.waterSpecificQuestions.sourceOfWater === 'Other') {
        await this.validationUtils.triggerBlur('source-water-other');
      }
      await this.validationUtils.triggerBlur('num-floors');
      await this.validationUtils.triggerBlur('what-floor');
    }

    async triggerOtherSpecificValidations(){
      await this.validationUtils.triggerBlur('loss-description');
      await this.validationUtils.triggerBlur('additional-info');
    }

    triggerLossDetailsValidations(){
      let inputEvent = new Event('input');

      this.triggerInput('date-of-discovery', inputEvent);
      this.lossOccurredValidationFlag = true;
      if (this.submission.lossDetails.doYouKnowDateLossOccurred) {
        this.triggerInput('date-of-loss', inputEvent);
      }
      this.lossCauseValidationFlag = true
    }

    triggerInput(id:string, event:Event){
      document.getElementById(id).dispatchEvent(event);
    }

    async navigateTo(pageName: string) {
      if (pageName === 'inventory-information') {
      await this.triggerValidations();
        if (!this.$store.state.lossDetailsIsValid) {
          window.scrollTo(0, 0);
          this.errorSnackbar = true;
          return;
        } else if (this.submission.lossDetails.lossCause == 'Theft' && !this.$store.state.theftSpecificQuestionsIsValid) {
          window.scrollTo(0, 0);
          this.errorSnackbar = true;
          return;
        }
      }
      this.$store.dispatch('saveSubmission', this.submission).then(() => {
        if(this.pageUtils.isServersideValid(this.$store.state.ssValidationError)) {
          this.$store.state.detailedLossCauseValidationFlag = false;
          this.$store.state.validationFlag = false;
          window.scrollTo(0, 0);
          this.$router.push({
            name: pageName
          });
        }
      });

    }

    enteredDateOfDiscoveryOnInput() {
      this.submission.lossDetails.dateOfDiscovery = this.dateUtils.parseFormattedDate(this.formattedDateOfDiscovery);
      this.dateOfDiscoveryValidationRules = this.runDateOfDiscoveryValidationRules();
      this.dateOfDiscoveryPicker = false;
    }

    dateOfDiscoveryOnInput() {
      this.formattedDateOfDiscovery = this.dateUtils.formatDateForDisplay(this.submission.lossDetails.dateOfDiscovery);
      this.dateOfDiscoveryValidationRules = this.runDateOfDiscoveryValidationRules();
      this.dateOfDiscoveryPicker = false;
    }

    timeOfDiscoveryOnInput() {
      if (null != this.submission.lossDetails.dateOfDiscovery) {
        this.formattedTimeOfDiscovery = this.dateUtils.formatTimeForDisplay(this.submission.lossDetails.dateOfDiscovery + " " +
          this.submission.lossDetails.timeOfDiscovery);
        this.timeOfDiscoveryPicker = !this.timeOfDiscoveryPicker;
      }
    }

    enteredDateOfLossOnInput() {
      this.submission.lossDetails.dateOfLoss = this.dateUtils.parseFormattedDate(this.formattedDateOfLoss);
      this.dateOfLossValidationRules = this.runDateOfLossValidationRules();
      this.dateOfLossPicker = false;
    }

    dateOfLossOnInput() {
      this.formattedDateOfLoss = this.dateUtils.formatDateForDisplay(this.submission.lossDetails.dateOfLoss);
      this.dateOfLossValidationRules = this.runDateOfLossValidationRules();
      this.dateOfLossPicker = false;
    }

    timeOfLossOnInput() {
      if (null != this.submission.lossDetails.dateOfLoss) {
        this.formattedTimeOfLoss = this.dateUtils.formatTimeForDisplay(this.submission.lossDetails.dateOfLoss + " " +
          this.submission.lossDetails.timeOfLoss);
        this.timeOfLossPicker = !this.timeOfLossPicker;
      }
    }

    lossCauseOnInput() {
      this.$store.state.detailedLossCauseValidationFlag = false;
      this.$store.state.validationFlag = false;

      if(this.lossCauseSelection != this.submission.lossDetails.lossCause){
        this.clearLossCauseRelatedFields();
        this.submission.lossDetails.lossCause = this.lossCauseSelection
      }

      this.setLossCauseCode();
      this.lossCauseValidationRules = this.runLossCauseValidationRules()
    }

    knowDateOfLossOnChange(){
      this.submission.lossDetails.dateOfLoss = null;
      this.submission.lossDetails.timeOfLoss = null;
      this.dateOfLossValidationRules = [];
    }

    clearLossCauseRelatedFields() {
      this.submission.lossDetails.detailedLossCause = null;
      this.submission.lossDetails.fireSpecificQuestions = new FireSpecificQuestions();
      this.submission.lossDetails.insectOrRodentSpecificQuestions = new InsectOrRodentSpecificQuestions();
      this.submission.lossDetails.moldOrMildewSpecificQuestions = new MoldOrMildewSpecificQuestions();
      this.submission.lossDetails.theftSpecificQuestions = new TheftSpecificQuestions();
      this.submission.lossDetails.waterSpecificQuestions = new WaterSpecificQuestions();
      this.submission.lossDetails.otherSpecificQuestions = new OtherSpecificQuestions();
    }

    setLossCauseCode() {
      if (this.submission.lossDetails.lossCause == null) {
        this.submission.lossDetails.lossCauseCode = null;
        return;
      }
      this.submission.lossDetails.lossCauseCode =
        $enum(LossCause).getKeyOrThrow(this.submission.lossDetails.lossCause);
    }

    runDateOfDiscoveryValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Date of Discovery is required',
        (v: any) => this.validationUtils.validateDateNotPastOrFuture(v) || 'Please enter a present or past date',
        (v: any) => this.validationUtils.validateDateNotBeforeAnotherDate(v, '01/01/1900') || 'Year must be greater than 1900',
        (v: any) => this.validationUtils.validateDateIsSameOrAfterAnotherDate(v, this.formattedDateOfLoss) || 'Date of Discovery must on or after the Loss Date',
        (v: any) => this.validationUtils.validateValidDate(v) || 'Please enter a valid date',
        (v: any) => this.validationUtils.validateDateIsValidLength(v) || 'Please enter a valid date'
      ];
      return rules;
    }

    runDateOfLossValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Date of Loss is required',
        (v: any) => this.validationUtils.validateDateNotPastOrFuture(v) || 'Please enter a present or past date',
        (v: any) => this.validationUtils.validateDateNotBeforeAnotherDate(v, '01/01/1900') || 'Year must be greater than 1900',
        (v: any) => this.validationUtils.validateDateIsSameOrBeforeAnotherDate(v, this.formattedDateOfDiscovery) || 'Loss Date must be on or before the Discovery Date',
        (v: any) => this.validationUtils.validateValidDate(v) || 'Please enter a valid date',
        (v: any) => this.validationUtils.validateDateIsValidLength(v) || 'Please enter a valid date'
      ];
      return rules;
    }

    runLossCauseValidationRules(): Array<any> {
      let rules = [
        (v: any) => !!v || 'Loss Cause is required'];
      return rules;
    }

    lossOccurredErrorMessage() {
      if (this.lossOccurredValidationFlag) {
        if (this.submission.lossDetails.doYouKnowDateLossOccurred === null) {
          return "Do you know the date the loss occurred? is required"
        } else {
          return null;
        }
      }
      return null;
    }

    lossCauseErrorMessages() {
      if (this.lossCauseValidationFlag) {
        if (this.submission.lossDetails.lossCause === null ||  this.submission.lossDetails.lossCause === '') {
          return "Loss Cause is required"
        } else {
          return null;
        }
      }
      return null;
    }
  }


import {Status} from "@/components/models/Status";
import {StorageSelectionDetails} from "@/components/models/StorageSelectionDetails";
import {TenantInformation} from "@/components/models/TenantInformation";
import {LossDetails} from "@/components/models/LossDetails";
import {AdditionalInformation} from "@/components/models/AdditionalInformation";
import {RentalProgramInformation} from "@/components/models/RentalProgramInformation";
import {InventoryInformation} from "@/components/models/InventoryInformation";
import {CommonData} from "@/components/models/CommonData";

export class FnolSubmission {
  submissionId!: string;
  reportNumber!: string;
  status!: Status;
  dateOfClaimStarted!: string;
  dateOfClaimSubmitted!: string;
  claimSubmitterFirstName!: string;
  claimSubmitterLastName!: string;
  claimSubmitterEmail!: string;
  businessUnit!: string;
  commonData: CommonData = new CommonData();
  rentalProgramInformation: RentalProgramInformation = new RentalProgramInformation();
  storageSelectionDetails: StorageSelectionDetails = new StorageSelectionDetails();
  tenantInformation: TenantInformation = new TenantInformation();
  lossDetails: LossDetails = new LossDetails();
  additionalInformation: AdditionalInformation = new AdditionalInformation();
  inventoryInformationList: Array<InventoryInformation> = new Array<InventoryInformation>();
}

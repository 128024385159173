export class TheftSpecificQuestions {
  notificationDate!: string;
  howNotified!: string;
  numberOfOtherAffectedUnits!: string;
  ceilingType!: string;
  doWallsExtendToCeiling!: Boolean;
  passcodeRequired!: Boolean;
  howIsUnitAccessed!: string;
  closestCamera!: string;
  authorizedUserList!: string;
  reportedToPolice!: Boolean;
  policeDeptContacted!: string;
  policeReportNumber!: string;
  whatTypeOfLockUsed!: string;
}

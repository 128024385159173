

import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import {ValidationUtils} from "@/components/utils/validation-utils";
import {FnolSubmission} from "@/components/models/FnolSubmission";
import {WaterSource} from "@/components/models/typelists/loss-cause/WaterSource";
import {WaterLocation} from "@/components/models/typelists/loss-cause/WaterLocation";
import {WaterLossCause} from "@/components/models/typelists/loss-cause/WaterLossCause";
import {$enum} from "ts-enum-util";
import {WaterSpecificValidations} from "@/components/utils/loss-cause-question-validations/water-specific-validations";

@Component
export default class WaterSpecificQuestionsPage extends Vue {

  @Prop()
  submission: FnolSubmission;

  lossDescriptionValidationRules: Array<string> = [];
  waterSourceValidationRules: Array<string> = [];
  waterSourceOtherValidationRules: Array<string> = [];
  waterLocationValidationRules: Array<string> = [];
  numFloorsValidationRules: Array<string> = [];
  whatFloorValidationRules: Array<string> = [];
  waterDetailsLossCauseValidationRules: Array<string> = [];
  validationUtils: ValidationUtils = new ValidationUtils();
  waterSourceList: Array<string> = Object.values(WaterSource);
  waterLocationList: Array<string> = Object.values(WaterLocation);
  waterLossCauseList: Array<string> = Object.values(WaterLossCause);
  waterSpecificValidationUtils: WaterSpecificValidations = new WaterSpecificValidations()

  created() {
    // Load rules arrays at page creation
    this.lossDescriptionValidationRules = this.waterSpecificValidationUtils.runLossDescriptionValidation();
    this.waterSourceOtherValidationRules = this.waterSpecificValidationUtils.runWaterSourceValidation();
    this.waterSourceOtherValidationRules = this.waterSpecificValidationUtils.runWaterSourceOtherValidation();
    this.waterLocationValidationRules = this.waterSpecificValidationUtils.runWaterLocationValidation();
    this.numFloorsValidationRules = this.waterSpecificValidationUtils.runNumFloorsValidation();
    this.whatFloorValidationRules = this.waterSpecificValidationUtils.runWhatFloorValidation();
    this.waterDetailsLossCauseValidationRules = this.waterSpecificValidationUtils.runLossDescriptionValidation();
  }

  waterDetailsLossCauseOnInput() {
    this.waterSpecificValidationUtils.runDetailLossCauseValidationRules();
    if (this.submission.lossDetails.detailedLossCause == null) {
      this.submission.lossDetails.detailedLossCauseCode = null;
      return;
    }
    this.submission.lossDetails.detailedLossCauseCode =
        $enum(WaterLossCause).getKeyOrThrow(this.submission.lossDetails.detailedLossCause);
  }
}

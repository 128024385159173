
import {Component, Vue} from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  certificateOfCoverage = 'Certificate Of Coverage';

  showFraudWarning() {
    if (this.$store.state.submission.rentalProgramInformation &&
        this.$store.state.submission.rentalProgramInformation.rentalProgramDocumentationOption === this.certificateOfCoverage) {
      return true;
    }
    return false;
  }
}

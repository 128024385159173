export enum WaterLossCause {
  '5101sprleakage' = 'Sprinkler Leakage',
  '5106waterdamage' =	'Unspecified Water Damage',
  '5107sprinklerburst' = 'Sprinkler Burst',
  '5108roofleak' = 'Roof Leak',
  '5109flood' = 'Flood',
  '5110surfacewater' = 'Surface Water',
  '5111watermainburst' = 'Water Main Burst',
  '5112waterpump'	= 'Water Pump Malfunction',
  '5199othersprinkler'	= 'Other Sprinkler or Water Damage'
}
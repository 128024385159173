import {CustomerServiceRep} from "@/components/models/CustomerServiceRep";

export class AdditionalInformation {
  rentPayments!: Boolean;
  managerNotified!: Boolean;
  unitInspectedByManager!: Boolean;
  unitPhotoByManager!: Boolean;
  consentToTextTenant!: Boolean;
  dateOfNotification!: string;
  managerFirstName!: string;
  managerLastName!: string;
  managerPhoneNumber!: string;
  customerServiceRep: CustomerServiceRep = new CustomerServiceRep();
}

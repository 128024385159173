

  import Component from "vue-class-component";
  import {Prop, Vue} from "vue-property-decorator";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {FireLossCause} from "@/components/models/typelists/loss-cause/FireLossCause";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {FireSpecificValidationUtils} from "@/components/utils/loss-cause-question-validations/fire-specific-validation-utils";
  import {$enum} from "ts-enum-util";

  @Component
  export default class FireSpecificQuestionsPage extends Vue {

    @Prop()
    submission: FnolSubmission;

    lossDescriptionValidationRules: Array<string> = [];
    fireOriginateValidationRules: Array<string> = [];
    extentOfDamageValidationRules: Array<string> = [];
    fireDetailLossCauseValidationRules: Array<string> = [];

    validationUtils: ValidationUtils = new ValidationUtils();
    fireSpecificValidationUtils = new FireSpecificValidationUtils();
    fireDetailLossCauseList: Array<string> = Object.values(FireLossCause);

    created() {
      // Load rules arrays at page creation
      this.lossDescriptionValidationRules = this.fireSpecificValidationUtils.runLossDescriptionValidation();
      this.fireOriginateValidationRules = this.fireSpecificValidationUtils.runFireOriginateValidation();
      this.extentOfDamageValidationRules = this.fireSpecificValidationUtils.runExtentOfDamageValidation();
      this.fireDetailLossCauseValidationRules = this.fireSpecificValidationUtils.runDetailLossCauseValidationRules();
    }

    lossDetailsCauseOnInput() {
      this.fireSpecificValidationUtils.runDetailLossCauseValidationRules();
      if (this.submission.lossDetails.detailedLossCause == null) {
        this.submission.lossDetails.detailedLossCauseCode = null;
        return;
      }
      this.submission.lossDetails.detailedLossCauseCode =
        $enum(FireLossCause).getKeyOrThrow(this.submission.lossDetails.detailedLossCause);
    }
  }

import {Vue} from "vue-property-decorator";
import {ValidationUtils} from "../validation-utils"

export class WaterSpecificValidations extends Vue {

  validationUtils: ValidationUtils = new ValidationUtils();

  runLossDescriptionValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Loss Description is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runWaterSourceValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Source of Water is required'
    ]
    return rules;
  }

  runWaterLocationValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Location of water on items? is required'
    ]
    return rules;
  }

  runWaterSourceOtherValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'What is the source of water if other? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runNumFloorsValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Number of floors in the storage facility is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 20) || 'Max 20 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runWhatFloorValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'What floor is your storage unit on? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 20) || 'Max 20 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runDetailLossCauseValidationRules(): Array<any>{
    const rules = [
      (v: any) => !!v || 'Detail Loss Cause is required'];
    return rules;
  }
}



  import Component from "vue-class-component";
  import {Prop, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {Status} from "@/components/models/Status";

  @Component
  export default class CancelClaimModal extends Vue {

    @Prop()
    submission: FnolSubmission;

    @Prop()
    showItemForm:boolean;

    created() {
    }

    cancelClaim() {
      this.$store.state.showCancelClaimModal = true;
    }

    yesButton() {
      this.$store.state.showCancelClaimModal = false;
      this.$store.state.submission.status = Status.CANCELLED;
      this.$store.dispatch('saveSubmission', this.$store.state.submission).then(() => {
          this.$store.state.submission.rentalProgramInformation = null;  // Reset to hide Fraud Warning in Footer.
          this.$router.push({
            name: 'landing-page'
          });
        });
    }

    noButton(){
      this.$store.state.showCancelClaimModal = false;
    }

  }

export class StorageSelectionDetails {
  facilityName!: string;
  facilityAddressLine1!: string;
  facilityCity!: string;
  facilityState!: string;
  facilityStateCode!: string;
  facilityZip!: string;
  facilityUnitNumber!: string;
  occupancyStartDate!: string;
  occupancyEndDate!: string;
}

import {Vue} from "vue-property-decorator";
import {ValidationUtils} from "../validation-utils"

export class MoldOrMildewValidationUtils extends Vue {

  validationUtils: ValidationUtils = new ValidationUtils();

  runLossDescriptionValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Loss Description is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runLocationDamageValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Location of mold or mildew damage? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 950) || 'Max 950 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runNumberOfFloorsValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'How many floors are in the storage facility? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 20) || 'Max 20 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runFloorOfStorageUnitValidation(): Array<any> {
    const rules = [
      (v: any) => !!v || 'What floor is your storage unit on? is required',
      (v: any) => this.validationUtils.validateMaxLength(v, 20) || 'Max 20 characters',
      (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];
    return rules;
  }

  runDetailLossCauseValidationRules(): Array<any> {
    const rules = [
      (v: any) => !!v || 'Detailed Loss Cause is required'];
    return rules;
  }
}

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import '@mdi/font/css/materialdesignicons.css'
import "@/styles/global.scss";
import store from "@/store/store";
// @ts-ignore
import VueTheMask from 'vue-the-mask';
// @ts-ignore
import VueSingleSelect from "vue-single-select";
import "regenerator-runtime/runtime";
import vuetify from './plugins/vuetify';
// @ts-ignore
import VCurrencyField from 'v-currency-field';
import {vMaska} from "maska/vue";

Vue.use(VueTheMask);
Vue.directive('maska', vMaska)

Vue.component('vue-single-select', VueSingleSelect);

Vue.use(VCurrencyField, {
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: null,
  currency: 'USD'
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");



  import Component from "vue-class-component";
  import {Prop, Vue} from "vue-property-decorator";
  import {ValidationUtils} from "@/components/utils/validation-utils";
  import {FnolSubmission} from "@/components/models/FnolSubmission";
  import {OtherSpecificValidation} from "@/components/utils/loss-cause-question-validations/other-specific-validations"

  @Component
  export default class OtherSpecificQuestionsPage extends Vue {

    @Prop()
    submission: FnolSubmission;

    lossDescriptionValidationRules: Array<string> = [];
    additionalInfoValidationRules: Array<string> = [];
    validationUtils: ValidationUtils = new ValidationUtils();
    otherSpecificValidationUtils: OtherSpecificValidation = new OtherSpecificValidation();

    created() {
      // Load rules arrays at page creation
      this.lossDescriptionValidationRules = this.otherSpecificValidationUtils.runLossDescriptionValidation();
      this.additionalInfoValidationRules = this.otherSpecificValidationUtils.runAdditionalInfoValidation();
      //default value for detailedLossCause when 'Other' is selected for Loss Cause
      this.submission.lossDetails.detailedLossCauseCode = '5801facilitydamage'
      this.submission.lossDetails.detailedLossCause = '5801-Facility Damage (prop)'
    }
  }

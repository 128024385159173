import Vue from 'vue';
import Vuetify, {VApp, VCardText, VCol, VContainer, VDatePicker, VForm, VMenu, VRow, VTextField} from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify, {
  components: {
    VApp,
    VContainer,
    VRow,
    VCol,
    VForm,
    VTextField,
    VCardText,
    VDatePicker,
    VMenu
  }
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#035489',
        secondary: colors.blue.darken2, // #1976D2
        accent: '#035489',
      },
    },
  },
  iconfont: 'mdi'
});

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.$route.name === 'rental-program-information'
          || this.$route.name === 'storage-selection'
          || this.$route.name === 'tenant-information'
          || this.$route.name === 'loss-details'
          || this.$route.name === 'inventory-information'
          || this.$route.name === 'additional-information')?_c('v-row',{attrs:{"data-cy":"mobile-stepper"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","color":"#003366"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"#ffffff"},attrs:{"data-cy":"mobile-stepper-current-page"}},[_vm._v("\n            "+_vm._s(_vm.$route.name === 'rental-program-information' ? 'Rental Program Information' :
            _vm.$route.name === 'storage-selection' ? 'Storage Facility Location' :
            _vm.$route.name === 'tenant-information' ? 'Tenant Information' :
            _vm.$route.name === 'loss-details' ? 'Loss Details' :
            _vm.$route.name === 'inventory-information' ? 'Inventory Information' :
            _vm.$route.name === 'additional-information' ? 'Additional Information' : '')+"\n          ")]),_vm._v(" "),_c('v-list-item-subtitle',{staticStyle:{"color":"#e0dcdc"},attrs:{"data-cy":"mobile-stepper-next-page"}},[_vm._v("\n            "+_vm._s(_vm.$route.name === 'rental-program-information' ? 'Next: Storage Facility Location' :
            _vm.$route.name === 'storage-selection' ? 'Next: Tenant Information' :
            _vm.$route.name === 'tenant-information' ? 'Next: Loss Details' :
            _vm.$route.name === 'loss-details' ? 'Next: Inventory Information' :
            _vm.$route.name === 'inventory-information' ? 'Next: Additional Information' :
            _vm.$route.name === 'additional-information' ? 'Next: Review and Submit' : '')+"\n          ")])],1),_vm._v(" "),_c('v-list-item-avatar',{attrs:{"size":"75","color":"grey"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":75,"width":10,"value":_vm.$route.name === 'rental-program-information' ? 16 :
            _vm.$route.name === 'storage-selection' ? 32 :
            _vm.$route.name === 'tenant-information' ? 48 :
            _vm.$route.name === 'loss-details' ? 64 :
            _vm.$route.name === 'inventory-information' ? 80 :
            _vm.$route.name === 'additional-information' ? 100 : 0,"color":"green"}},[_c('span',{staticStyle:{"color":"#ffffff"},attrs:{"data-cy":"mobile-stepper-page-number"}},[_vm._v("\n              "+_vm._s(_vm.$route.name === 'rental-program-information' ? '1 of 6' :
            _vm.$route.name === 'storage-selection' ? '2 of 6' :
            _vm.$route.name === 'tenant-information' ? '3 of 6' :
            _vm.$route.name === 'loss-details' ? '4 of 6' :
            _vm.$route.name === 'inventory-information' ? '5 of 6' :
            _vm.$route.name === 'additional-information' ? '6 of 6' : '')+"\n            ")])])],1)],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"reportNumber",attrs:{"cols":"12"}},[_vm._v("\n    Report #:\n    "),_c('span',{attrs:{"data-cy":"mobile-stepper-report-number"}},[_vm._v(_vm._s(this.uiUtils.checkNullBlankUndefined(this.$store.state.submission.reportNumber)))])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }